import React, { useEffect } from "react";
import Select from 'react-select'
import { useState } from "react";
import axios from "axios";
import RowMatchDate from "./RowMatchDate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ApriGiornata(props) {
    const [lega, setLega] = React.useState(0);
    const [round, setRound] = React.useState('');
    const [fixtures, setFixtures] = React.useState([]);
    const [matches, setMatches] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [matchday_status, setMatchdayStatus] = React.useState('');

    const [options2, setOptions2] = React.useState([]);
    const weekday = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];
    const setLeagues = (data) => {
        let obj = []
        data.map((l, i) => {
            obj.push({ value: l.idLeague, label: l.name })
        })
        setOptions2(obj)
    }
    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })
            .then(response => response.json())
            .then(result => {
                setLeagues(result.response)
                if (result.response.length > 0) {
                    setLega(result.response[0].idLeague)
                    getGiornata(result.response[0].idLeague)
                }
            }).catch((err) => {
                console.log(err)
            })
    }
    const setLegaValue = (val) => {
        setLoading(true)
        setLega(val.value)
        setMatches([])
        setMatchdayStatus('')
        setTimeout(() => {
            getGiornata(val.value).then((res) => {
                /* if (matchday_status === 'POST' || matchday_status === 'SCHEDULED')
                     getRound(val.value, res)*/
            })
        }, '1000');
    }
    const d = new Date()
    d.setSeconds(0)
    d.setHours(15)
    d.setMinutes(0)
    const [value, setValue] = useState(d);
    const [longMatchDay, setLongMatchDay] = React.useState(d.getTime());

    const handleChange = (e) => {
        let d = new Date(e)
        d.setSeconds(0)


        setValue(d)
        setLongMatchDay(d.getTime())
    }
    const setMatchDate = (m, d) => {
        let matchesTmp = matches
        matchesTmp.map((match) => {
            if (match.awayTeam.name === m.awayTeam.name && match.homeTeam.name === m.homeTeam.name) {
                m.date = d
            }
        })
        setMatches(matchesTmp)
    }
    const setHomePerc = (m, d) => {

        let matchesTmp = matches
        matchesTmp.map((match) => {
            if (match.awayTeam.name === m.awayTeam.name && match.homeTeam.name === m.homeTeam.name) {
                m.homePerc = d
            }
        })
        setMatches(matchesTmp)
    }
    const setHomePoints = (m, d) => {

        let matchesTmp = matches
        matchesTmp.map((match) => {
            if (match.awayTeam.name === m.awayTeam.name && match.homeTeam.name === m.homeTeam.name) {
                m.homePoints = d
            }
        })
        setMatches(matchesTmp)
    }
    const setAwayPerc = (m, d) => {
        let matchesTmp = matches
        matchesTmp.map((match) => {
            if (match.awayTeam.name === m.awayTeam.name && match.homeTeam.name === m.homeTeam.name) {
                m.awayPerc = d
            }
        })
        setMatches(matchesTmp)
    }
    const setAwayPoints = (m, d) => {
        let matchesTmp = matches
        matchesTmp.map((match) => {
            if (match.awayTeam.name === m.awayTeam.name && match.homeTeam.name === m.homeTeam.name) {
                m.awayPoints = d
            }
        })
        setMatches(matchesTmp)
    }
    const getGiornata = async (val) => {
        return new Promise(async (resolve, reject) => {
            axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
            axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/nextCurrentHome?idLeague=" + val).then(function (response) {
                if (response && response.data && response.data.response && response.data.response.matches) {
                    setMatches(response.data.response.matches)
                    status(val, response.data.response.matches)
                    resolve(response.data.response.matches)
                } else {
                    setLoading(false)
                    setTimeout(() => {
                        toast.error('Qualcosa è andato storto!')
                    }, 200);
                    resolve([])
                }
            }).catch(error => {
                console.log('error', error)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Qualcosa è andato storto!')
                }, 200);
                reject(error)
            });


        })
    }
    const getRound = async (val, matches) => {
        let league = 135
        if (val == 1) {
            league = 136
        }
        else if (val == 2) {
            league = 2
        }
        else if (val == 3) {
            league = 943
        }
        let d = new Date()
        var myHeaders = new Headers();
        myHeaders.append("x-rapidapi-host", 'v3.football.api-sports.io');
        myHeaders.append("x-rapidapi-key", '32e668a32fcadc8d56bfc007a4117f3c');
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        const res = await fetch('https://v3.football.api-sports.io/fixtures/rounds?season=2023&league=' + league + '&current=true', requestOptions)
            .then(response => response.json())
            .then(result => {

                result.response.map((r) => {

                    setRound(r)
                    getFixtures(val, r, matches)
                })
            })

            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });

        setLoading(false)
    }
    const getFixtures = async (leg, val, matches) => {
        let league = 135
        if (leg == 1) {
            league = 136
        } else if (leg == 2)
            league = 2
        else if (leg == 3)
            league = 943
        /*  else if (leg == 2)
              league = 1*/
        let d = new Date()



        var myHeaders = new Headers();
        myHeaders.append("x-rapidapi-host", 'v3.football.api-sports.io');
        myHeaders.append("x-rapidapi-key", '32e668a32fcadc8d56bfc007a4117f3c');



        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        const res = await fetch('https://v3.football.api-sports.io/fixtures?league=' + league + '&season=2023&timezone=Europe/Rome&round=' + val, requestOptions)
            .then(response => response.json())
            .then(result => {
                setFixtures(result.response)
                if (matches)
                    setDefaultDates(leg, result.response, matches)
                setLoading(false)

            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)
            });

    }
    const getCacheType = () => {
        return new Promise((resolve, reject) => {
            let myHeaders1 = new Headers();

            myHeaders1.append("jwt", sessionStorage.getItem('val'));
            myHeaders1.append("Content-Type", 'application/json');
            myHeaders1.append("appVersion", '1000');

            let options = {
                method: 'GET',
                headers: myHeaders1,
                redirect: 'follow',
            }
            let res2 = fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/cachingMethod", options)
                .then(response => response.json())
                .then(result => {
                    resolve(result.response)
                }).catch((err) => {
                    reject(err)
                })
        })
    }
    const lambdaFunction = () => {
        return new Promise((resolve, reject) => {
            let toSend = []
            matches.map((match) => {
                toSend.push({
                    homeTeam: match.homeTeam.name,
                    awayTeam: match.awayTeam.name,
                    date: new Date(match.date).getTime(),
                    homePerc: parseInt(match.homePerc),
                    awayPerc: parseInt(match.awayPerc),
                    homePoints: parseInt(match.homePoints),
                    awayPoints: parseInt(match.awayPoints)
                })
            })
            let min = new Date(toSend[0].date).getTime()
            matches.map((match) => {
                if (new Date(match.date).getTime() < min) {
                    min = new Date(match.date).getTime()
                }
            })
            let value = min - 900000
            if (options2.length > 0) {
                let event = {
                    'UTCDateMs': value,
                    'idLeague': getValue(lega)
                }
                let body = {
                    'body': event
                }
                var myHeaders2 = new Headers();
                var requestOptions2 = {

                    method: 'POST',
                    headers: myHeaders2,
                    redirect: 'follow',
                    body: JSON.stringify(event)

                }

                const res2 = fetch("https://5utnqbumwr4zvdyohztwg6dtte0oobgl.lambda-url.eu-west-2.on.aws/scheduleOngoing", requestOptions2)
                    .then(response => response.json())
                    .then(result => {
                        toast.success('Ongoing schedulato per ' + new Date(value))
                        resolve('ok')
                    }).catch(() => {
                        toast.error('Errore: Lambda non schedulata!')
                        reject('ko')
                    })

            }
        })
    }

    const open = async (data) => {
        setLoading(true)
        let errore = false
        let myHeaders = new Headers();

        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("Content-Type", 'application/json');
        myHeaders.append("appVersion", '1000');

        matches.map((match) => {
            if (!errore) {
                if (!match.homePerc || !parseInt(match.homePerc) || parseInt(match.homePerc) < 1 || parseInt(match.homePerc) > 100 || !match.awayPerc || !parseInt(match.awayPerc) || parseInt(match.awayPerc) < 1 || parseInt(match.awayPerc) > 100) {
                    toast.error('Percentuale mancante o non valida per il match ' + match.homeTeam.name + ' - ' + match.awayTeam.name)
                    errore = true;
                    setLoading(false)
                }
                else if (!match.homePoints || !match.awayPoints) {
                    toast.error("Punti non validi per il match " + match.homeTeam.name + ' - ' + match.awayTeam.name)
                    errore = true;
                    setLoading(false)
                }
            }

        })
        if (!errore) {
            let toSend = []
            matches.map((match) => {
                toSend.push({
                    homeTeam: match.homeTeam.name,
                    awayTeam: match.awayTeam.name,
                    date: new Date(match.date).getTime(),
                    homePerc: parseInt(match.homePerc),
                    awayPerc: parseInt(match.awayPerc),
                    homePoints: parseInt(match.homePoints),
                    awayPoints: parseInt(match.awayPoints)
                })
            })
            getCacheType().then((data) => {


                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(toSend)
                }
                if (data === 'redis') {

                    lambdaFunction().then(() => {

                        const res = fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/league/" + getValue(lega), requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.server == 403) {
                                    toast.error('Qualcosa è andato storto!')
                                    setLoading(false)
                                }
                                else {
                                    toast.success('Giornata aperta correttamente')
                                    setLoading(false)
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 4000);
                                }

                            })
                            .catch(error => {
                                setLoading(false)
                                console.log('error', error)
                                toast.error('Giornata non aperta. Qualcosa  è andato storto')
                            });
                        return res

                    }).catch(() => {
                        setLoading(false)
                        toast.error('Schedulazione non avvenuta. Qualcosa è andato storto')
                    })
                }
                else {
                    const res = fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/league/" + getValue(lega), requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.server == 403) {
                                toast.error('Qualcosa è andato storto!')
                                setLoading(false)
                            }
                            else {
                                setLoading(false)
                                toast.success('Giornata aperta correttamente')
                                setTimeout(() => {
                                    window.location.reload()
                                }, 4000);
                            }

                        })
                        .catch(error => {
                            setLoading(false)
                            console.log('error', error)
                            toast.error('Giornata non aperta. Qualcosa  è andato storto')
                        });
                    return res
                }
            }).catch(() => {
                toast.error('Errore: Non posso leggere il tipo di cache')
            })
        }
    }
    const getTranslationName = (value) => {
        if (JSON.parse(localStorage.getItem('translations'))[value])
            return JSON.parse(localStorage.getItem('translations'))[value].toLowerCase()
        else
            return value.toLowerCase()
    }
    const setDefaultDates = (leg, fixtures, matches) => {
        let dateMatches = matches
        dateMatches.map((m) => {
            fixtures.map((f) => {
                if (f.teams.home.name.toLowerCase() === getTranslationName(m.homeTeam.name) || f.teams.home.name.toLowerCase().includes(getTranslationName(m.homeTeam.name)))
                    m.date = f.fixture.date
            })
        })
        setTimeout(() => {

            setMatches(dateMatches)
        }, 100);
    }
    const status = async (val, matches) => {

        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/currentStatus?idLeague=" + val, requestOptions)
            .then(response => response.json())
            .then(result => {
                setMatchdayStatus(result.response)
                if (result.response !== '' && (result.response === 'POST' || result.response === 'SCHEDULED')) {
                    getRound(val, matches)
                }
                else {
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });

        return res
    }
    useEffect(() => {
        getLeghe()

    }, [])
    const getLabel = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.label
        })
        return toReturn
    }
    const getValue = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.value
        })
        return toReturn
    }
    let recap = ''
    //recap = <div className="mt-10 text"> Le giocate della lega di {options2[lega].label} si chiuderanno {weekday[value.getDay()]} {value.toLocaleDateString('it')} alle {value.toLocaleTimeString('it')} </div>
    if (value != null && options2.length > 0)
        recap = <div className="mt-10 text"> Le giocate della lega di {getLabel(lega)} si chiuderanno 15 min prima dell'inizio del primo match. </div>

    let matchesInput = 'w'
    matchesInput = matches.map((m, index) => <React.Fragment> {(m.awayTeam.name && m.homeTeam.name) ? <RowMatchDate key={index} match={m} setMatchDate={setMatchDate} setHomePerc={setHomePerc}
        setAwayPerc={setAwayPerc} setAwayPoints={setAwayPoints} setHomePoints={setHomePoints} /> : ''}</React.Fragment>)

    return (
        loading || Object.is(lega, null) ? <div className="loader_cont"><div className="loader"></div></div>
            : options2.length > 0 ? <div>
                <div className="w-100 text text-center-surv ">
                    <Select className="w-60 surv-select mt-10" options={options2} onChange={(e) => setLegaValue(e)} value={getValue(lega)} placeholder={getLabel(lega)} />
                    {matchday_status === 'POST' || matchday_status === 'SCHEDULED' ? <>
                        <div className="mt-10">
                            {/*   <DateTimeComponent value={value} handleChange={handleChange} />*/}

                        </div>
                        {value != null ? recap : ''}
                        <div className="text-center-surv mt-10"> {matches ? matchesInput : ''} </div>
                        <button className='button ' onClick={() => open()} > Apri Giornata</button>
                    </>
                        : <div className="text mt-10"> Giornata corrente ancora in corso</div>}
                </div>
                <ToastContainer theme="colored" />
            </div> : ''
    )

}