import axios from "axios";
import React, { useEffect } from "react";
import Select from 'react-select'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DateTimeComponent from "./DateTimeComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Dashboard(props) {

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [lega, setLega] = React.useState(null);
    const [labels, setLabels] = React.useState([]);
    const [totale, setTotale] = React.useState(0);
    const [percentuale, setPercentuale] = React.useState(0);
    const [playedUsers, setPlayedUsers] = React.useState(0);
    const [dataset, setDataset] = React.useState([]);
    const [scheduledOperations, setScheduledOperations] = React.useState([]);
    const [options2, setOptions2] = React.useState([]);
    const [editing, setEditing] = React.useState(false);
    const [editingSchedule, setEditingSchedule] = React.useState(null)
    const bg = ['rgba(253, 15, 65, 0.8)',
        'rgba(54, 162, 235, 0.8)',
        'rgba(228, 247, 70, 0.8)',
        'rgba(55, 196, 113, 0.8)',
        'rgba(153, 102, 255, 0.8)',
        'rgba(255, 159, 64, 0.8)']

    const setLeagues = (data) => {
        let obj = []
        data.map((l, i) => {
            obj.push({ value: l.idLeague, label: l.name })
        })
        setOptions2(obj)
    }

    const setLegaValue = (val) => {
        setLoading(true)
        setLega(val.value)
        setData([])
        getData(val.value)
    }
    const getData = async (val) => {
        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/livesNotPlayed?idLeague=" + val).then(function (response) {
            setData(response.data.response)
            let lab = []
            let datas = []
            let tot = 0
            let attive = 0
            response.data.response.map((e) => {
                //  lab.push(e.name + ' ' + e.attive + ' ' + e.not_played + parseFloat((e.not_played * 100) / e.attive).toFixed(1) + '%')
                lab.push(e.name)
                datas.push(e.not_played)
                tot += e.not_played
                attive += e.attive
            })
            setPercentuale(parseFloat((tot * 100) / attive).toFixed(1))
            setTotale(tot)
            setLabels(lab)
            setDataset(datas)
            setLoading(false)

        }).catch(error => {
            console.log('error', error)
            setLoading(false)
        });
    }
    const getScheduledOperations = () => {

        var myHeaders2 = new Headers();
        var requestOptions2 = {

            method: 'GET',
            headers: myHeaders2,
            redirect: 'follow',

        }
        fetch("https://5utnqbumwr4zvdyohztwg6dtte0oobgl.lambda-url.eu-west-2.on.aws/getRules", requestOptions2)
            .then(response => response.json())
            .then(result => {
                setScheduledOperations(result)

            }).catch(error => {
                console.log('error', error)
            });
    }
    const getPlayedUsers = async () => {
        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/usersPlayed").then(function (response) {

            setPlayedUsers(response.data.response)

        }).catch(error => {
            console.log('error', error)
        });
    }
    const saveDate = async () => {
        let d = new Date(editingSchedule.scheduledDate).getTime()
        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.put(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/rescheduleOngoing?idLeague=" + editingSchedule.idLeague + "&date=" + d).then(function (response) {

            setEditing(false)
            setEditingSchedule(null)
            toast.success('Data aggiornata correttamente!')
            setTimeout(() => {
                setLoading(true);
                reload(lega);
            }, 1000);
        }).catch(error => {
            toast.error('Qualcosa è andato storto!')
            console.log('error', error)
        });
    }
    const cancel = () => {
        setEditing(false)
        setEditingSchedule(null)
    }
    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })

            .then(response => response.json())
            .then(result => {
                setLeagues(result.response)
                if (result.response.length > 0) {
                    setLega(result.response[0].idLeague)
                    reload(result.response[0].idLeague)
                }
            }).catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        getLeghe()
    }, [])

    const reload = (lega) => {
        getPlayedUsers()
        getData(lega)
        getScheduledOperations()

    }
    const data_doughnuts = {
        labels: labels,
        datasets: [
            {
                label: 'Non giocate',
                data: dataset,
                backgroundColor: [
                    'rgba(253, 15, 65, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(228, 247, 70, 0.8)',
                    'rgba(55, 196, 113, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                ],
                borderColor: [
                    'rgba(253, 15, 65, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(228, 247, 70, 1)',
                    'rgba(55, 196, 113, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const edit = (value) => {
        setEditing(true)
        setEditingSchedule(value)
    }

    const handleChangeDate = (e) => {
        let tmp = editingSchedule
        tmp.scheduledDate = new Date(e)
        setEditingSchedule({ ...tmp })
    }

    const getColor = (index) => {
        let value = index
        if (index >= 6)
            value = parseInt(index % 6)
        return bg[value]
    }

    const getLabel = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.label
        })
        return toReturn
    }
    const getValue = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.value
        })
        return toReturn
    }
    ChartJS.register(ArcElement, Tooltip, Legend);

    let body_table = ''
    if (data)
        body_table = data.map((e, index) => <tr className="legend_tr">

            <td className='legend_td' Style={'min-width:2px'} ><div Style={"background-color: " + getColor(index) + ";  width: 4px;    height: 25px;"}></div></td>
            <td className='legend_td'  >{e.idEdition}</td>
            <td className='legend_td' >{e.name}</td>
            <td className='legend_td' >{e.not_played}/{e.attive}</td>
        </tr>)
    const options = {
        responsive: true,

        plugins: {

            legend: {
                display: false,

                position: "right",
                alignText: 'end',
                title: {
                    display: true,
                    text: 'Nome - Attive - NonGiocate',
                    font: {
                        size: 20
                    },
                },

                fullSize: true,
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 19
                    }
                },
                layout: {
                    padding: {
                        left: 50,
                        right: 130,
                        top: 0,
                        bottom: 0
                    }
                }
            },
            tooltip: {
                label: {
                    font: {
                        size: 20
                    }
                },
                callbacks: {
                    title: function (label) {
                        return label[0].label
                    }
                },
            },


        }
    };
    return (<div>
        {loading || Object.is(lega, null) ? <div className="loader_cont">< div className="loader" ></div></div >
            : options2.length > 0 ? <div>
                <div className="w-100 text text-center-surv ">
                    <Select className="w-60 surv-select mt-10" options={options2} onChange={(e) => setLegaValue(e)} value={getValue(lega)} placeholder={getLabel(lega)} />
                </div>
                <div className=" dashboard_title mt-10" >Vite non giocate</div>
                {data && data.length > 0 ?
                    <div className="">
                        <div className="doughnut_chart_card flex_dashboard mt-10">
                            <div className="w-45 " Style={'text-align: -webkit-center;    align-self: center;    margin-top: -100px;'}>
                                <div className="w-80 dashboard_card_w relative ">
                                    <div className="totale">{totale} ({percentuale}%)</div>
                                    <Doughnut
                                        data={data_doughnuts}
                                        width={10}
                                        height={10}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="dashboard_table" Style={'max-height: 650px;    position: relative;    overflow: auto;'}>
                                < table >
                                    <thead>
                                        <tr>
                                            <th className="legend_th"></th>
                                            <th className="legend_th">Id</th>
                                            <th className="legend_th">Nome</th>
                                            <th className="legend_th">Non Giocate</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {body_table}
                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className=" dashboard_title mt-10" >Edizioni attive</div>
                        <div className="counter_card">
                            <div className="counter_text">{data.length} </div>
                        </div>

                        <div className=" dashboard_title mt-10" >Utenti che hanno giocato</div>
                        <div className="counter_card" Style={'margin-bottom:100px'}>
                            <div className="counter_text">{playedUsers} </div>
                        </div>
                        {scheduledOperations.length > 0 ? <React.Fragment>
                            <div className=" dashboard_title mt-10" >Operazioni schedulate</div>
                            <table className="operations_table">
                                {scheduledOperations.map((t) =>
                                    <tr>

                                        <td>{getLabel(t.idLeague)}</td>
                                        <td>
                                            {editing && editingSchedule && editingSchedule.scheduledDate && editingSchedule.idLeague == t.idLeague ?
                                                <div Style={'margin-top:-50px'}>

                                                    <DateTimeComponent handleChange={handleChangeDate} value={new Date(editingSchedule.scheduledDate)} />
                                                </div>
                                                : t.scheduled}</td>

                                        <td>{!editing ?
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => edit(t)} width="16" height="16" fill="currentColor" class="bi bi-pencil-square pointer" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                            </svg> : (editingSchedule && editingSchedule.idLeague == t.idLeague ?
                                                <>
                                                    <svg onClick={() => saveDate()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check pointer" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                                    </svg>
                                                    <svg onClick={() => cancel()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square ml-10 pointer" viewBox="0 0 16 16">
                                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                    </svg>
                                                </>
                                                : '')}</td>
                                        <td>{t.operation}</td>
                                        <td><div className={!t.active ? "inactive_operation" : "active_operation"}></div></td>
                                    </tr>

                                )}


                            </table>
                        </React.Fragment>
                            : ''}

                    </div>
                    : <div className="w-100 text text-center-surv-title mt-10" >Non ci sono vite non giocate</div>}
            </div >
                : ''
        }
        <button className='round_button' onClick={() => { setLoading(true); reload(lega); }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-arrow-clockwise mt--15" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg></button>
        <ToastContainer theme="colored" />
    </div >)
}
