import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import Homepage from './Homepage';
import React from 'react';
import Login from './Login';
import axios from "axios";
import { EmailAuthProvider, GoogleAuthProvider, getAuth, linkWithCredential, signInWithPopup } from "firebase/auth";

function App() {
  const [pass, setPass] = React.useState('')
  const [env, setEnv] = React.useState('')

  const login = (val, env) => {
    setEnv(env)
    axios.defaults.headers.common['jwt'] = 't';
    axios.defaults.headers.common['appVersion'] = '10000';
    axios.defaults.headers.common['operation'] = val;
    axios.post(env + "/survivor/api/v1/Admin/login").then(function (response, header) {
      sessionStorage.setItem('val', response.headers.jwt)
      sessionStorage.setItem('env', env)
      getTranslation()
      setTimeout(() => {

        window.location.reload()
      }, 1000);

    }).catch(error => {
      console.log('error', error)
      alert('Password sbagliata! Ritenta!')
    });

  }
  const getTranslation = () => {
    axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
    axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Team/getTranslations").then(function (response) {
      localStorage.setItem('translations', JSON.stringify(response.data.response))

    }).catch(error => {
      console.log('error', error)
    });
  }


  return (
    <div className="App">
      {sessionStorage.getItem('val') && sessionStorage.getItem('env') ? <Homepage /> : <Login pass={pass} login={login} />}
    </div>
  );
}

export default App;
