
import { getAuth, signOut } from 'firebase/auth';
import React from 'react';

export default function Headers(props) {
    const [close, setClose] = React.useState(false);
    const action = (act) => {
        props.setActive(act)
        setClose(true)
    }
    const logout = () => {
        sessionStorage.clear()


        setTimeout(() => {
            window.location.reload()
        }, 200);
    }
    return (
        <nav className="bg-navbar navbar navbar-expand-lg" >
            <div class="container-fluid">
                <img className="header_logo" src='https://skillbol.com/images/logo_text_500.png' />
                <div className="sub_header_logo  surv-text">Operations</div>
                <button onClick={() => setClose(!close)} className={!close ? "navbar-toggler" : "navbar-toggler"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="white" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
                <div class={close ? "collapse navbar-collapse" : 'collapse navbar-collapse show'} id="navbarText">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <div className={props.active === 'dashboard' ? "nav-link active  selected_navbar pointer text" : 'nav-link pointer text'} aria-current="page" onClick={() => action('dashboard')}>Dashboard</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'chiudi_match' ? "nav-link active  selected_navbar pointer text" : 'nav-link pointer text'} aria-current="page" onClick={() => action('chiudi_match')}>Chiusura Match</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'chiudi_giornata' ? "nav-link active  selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('chiudi_giornata')}>Chiusura Giornata</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'apri_giornata' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('apri_giornata')}>Apertura Giornata</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'edizioni' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('edizioni')}>Edizioni</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'cache' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('cache')}>Cache</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'configurations' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('configurations')}>Configurations</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'leghe' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('leghe')}>Leghe</div>
                        </li>
                        <li class="nav-item">
                            <div className={props.active === 'notifiche' ? "nav-link active selected_navbar pointer text" : 'nav-link pointer text'} onClick={() => action('notifiche')}>Notifiche</div>
                        </li>
                        <li class="nav-item">
                            <div className="nav-link pointer text" onClick={() => logout()}>Logout</div>
                        </li>
                    </ul>

                </div>
            </div>
        </nav >
    )
}