import React, { useEffect } from "react";
import Select from 'react-select'
import { Stepper } from 'react-form-stepper';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

export default function ChiudiGiornata(props) {
    const [lega, setLega] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [matchday_status, setMatchdayStatus] = React.useState('');

    const [options2, setOptions2] = React.useState([]);
    const setLeagues = (data) => {
        let obj = []
        data.map((l, i) => {
            obj.push({ value: l.idLeague, label: l.name })
        })
        setOptions2(obj)
    }
    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })
            .then(response => response.json())
            .then(result => {
                setLeagues(result.response)
                if (result.response.length > 0) {
                    setLega(result.response[0].idLeague)
                    status(result.response[0].idLeague)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const setLegaValue = (val) => {
        setLega(val.value)
        status(val.value)
    }
    useEffect(() => {
        getLeghe()


    }, [])
    const status = async (val) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }

        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/currentStatus?idLeague=" + val, requestOptions)
            .then(response => response.json())
            .then(result => {
                setMatchdayStatus(result.response)
                setTimeout(() => {

                    setLoading(false)
                }, 100);
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });

        return res
    }
    const getLabel = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.label
        })
        return toReturn
    }
    const getValue = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.value
        })
        return toReturn
    }
    const close = async () => {
        setLoading(true)

        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.defaults.headers.common['appVersion'] = '1000';

        axios.put(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/close/league?idLeague=" + getValue(lega)).then(function (res) {
            setLoading(false)
            toast.success('giornata chiusa correttamente')
            status(lega)
        }).catch(error => {
            setLoading(false)
            toast.error('Qualcosa è andato storto')
            console.log('error', error)
        });
    }
    let config = {
        activeBgColor: "orange",
        activeTextColor: "orange",
        completedBgColor: "green",
        completedTextColor: "green",
        inactiveBgColor: "grey",
        inactiveTextColor: "grey"
    };
    let connectorConfig = {
        completedColor: 'green'
    }
    let config2 = {
        activeBgColor: "red",
        activeTextColor: "red",
        completedBgColor: "red",
        completedTextColor: "red",
        inactiveBgColor: "red",
        inactiveTextColor: "red"
    };
    if (loading || Object.is(lega, null))
        return (<div className="loader_cont"><div className="loader"></div></div>)
    else if (options2.length > 0)
        return (<div>
            <div className="w-100 text-center-surv ">
                <Select className="w-60 surv-select mt-10" options={options2} onChange={(e) => setLegaValue(e)} value={getValue(lega)} placeholder={getLabel(lega)} />
            </div>
            <button disabled={matchday_status === 'ONGOING' ? false : true} className='button' onClick={() => close()} > Chiudi Giornata</button>
            <div className="text">Stato corrente: </div>
            <div className="text-center-surv">
                {matchday_status === 'PRE' ? <Stepper
                    connectorStateColors={true}
                    steps={[{ label: 'PRE', active: true }, { label: 'ONGOING', active: false, }, { label: 'POST', active: false, }]}
                    styleConfig={config}
                />
                    : (matchday_status === 'ONGOING' ?
                        <Stepper
                            steps={[{ label: 'PRE', completed: true }, { label: 'ONGOING', completed: true, }, { label: 'POST', active: false }]}
                            styleConfig={config}
                            connectorStateColors={true}
                            connectorStyleConfig={connectorConfig}
                        />
                        :
                        <Stepper
                            steps={[{ label: 'PRE', completed: true }, { label: 'ONGOING', completed: true, }, { label: 'POST', completed: true }]}
                            styleConfig={config2}
                            connectorStateColors={true}
                        />
                    )}
            </div>

            <ToastContainer theme="colored" />
        </div>
        )
    else
        return ('')
}