import { useEffect, useState } from "react"
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Cache(props) {
    const [edition_id, setEditionId] = useState(null)
    const [options2, setOptions2] = useState(null)
    const [lega, setLega] = useState(null)
    const [matchdayId, setMatchdayId] = useState('tutte')

    const setLegaValue = (val) => {
        console.log(val.value)
        setLega(val.value)

    }
    const handleEdition = (e) => {
        setEditionId(e.target.value)

    }
    const handleQuote = (e) => {
        setMatchdayId(e.target.value)

    }
    const setLeagues = (data) => {
        let obj = []
        data.map((l) => {
            obj.push({ value: l.idLeague, label: l.name })
        })
        setOptions2(obj)
    }
    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }

        })
            .then(response => response.json())
            .then(result => {
                setLeagues(result.response)
                if (result.response.length > 0) {
                    setLega(result.response[0].idLeague)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getLeghe()
    }, [])
    const getLabel = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.label
        })
        return toReturn
    }
    const getValue = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.value
        })
        return toReturn
    }
    const removeCache = (type) => {
        switch (type) {
            case 'reset':

                fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/removeCache ', {
                    method: 'POST',
                    headers: {
                        'jwt': sessionStorage.getItem('val'),
                        'Content-Type': 'application/json',
                        'appVersion': '1000'
                    }
                }).then(response => {
                    if (response.status == 200)
                        toast.success('Reset effettuato con successo!')
                    else {
                        toast.error('Errore!')
                    }
                }
                ).catch((err) => {
                    console.log(err)
                })
                break;
            case 'configuration':
                fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/removeCacheConfigurations ', {
                    method: 'POST',
                    headers: {
                        'jwt': sessionStorage.getItem('val'),
                        'Content-Type': 'application/json',
                        'appVersion': '1000'
                    }
                }).then(response => {
                    if (response.status == 200)
                        toast.success('Reset effettuato con successo!')
                    else {
                        toast.error('Errore!')
                    }
                }
                ).catch((err) => {
                    console.log(err)
                })
                break;

            case 'ranking':
                fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/removeCacheRankings ', {
                    method: 'POST',
                    headers: {
                        'jwt': sessionStorage.getItem('val'),
                        'Content-Type': 'application/json',
                        'appVersion': '1000'
                    }
                }).then(response => {
                    if (response.status == 200)
                        toast.success('Reset effettuato con successo!')
                    else {
                        toast.error('Errore!')
                    }
                }
                ).catch((err) => {
                    console.log(err)
                })
                break;
            case 'update_ranking':
                fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/tryRefreshRankings/' + getValue(lega), {
                    method: 'POST',
                    headers: {
                        'jwt': sessionStorage.getItem('val'),
                        'Content-Type': 'application/json',
                        'appVersion': '1000'
                    }
                }).then(response => {
                    if (response.status == 200)
                        toast.success('Reset effettuato con successo!')
                    else {
                        toast.error('Errore!')
                    }
                }
                ).catch((err) => {
                    console.log(err)
                })
                break;
            case 'competition':
                if (!edition_id)
                    toast.warn('Inserisci id della competizione')
                else {

                    fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/removeCacheEdition/' + edition_id, {
                        method: 'POST',
                        headers: {
                            'jwt': sessionStorage.getItem('val'),
                            'Content-Type': 'application/json',
                            'appVersion': '1000'
                        }
                    }).then(response => {
                        if (response.status == 200)
                            toast.success('Reset effettuato con successo!')
                        else {
                            toast.error('Errore!')
                        }
                    }
                    ).catch((err) => {
                        console.log(err)
                    })
                }
                break;
            case 'quote':

                let param_api = ''
                if (matchdayId !== 'tutte' && matchdayId !== '') {
                    param_api = '?idMatchday=' + matchdayId
                }
                fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/removeCacheOdds' + param_api, {
                    method: 'POST',
                    headers: {
                        'jwt': sessionStorage.getItem('val'),
                        'Content-Type': 'application/json',
                        'appVersion': '1000'
                    }

                }).then(response => {
                    if (response.status == 200)
                        toast.success('Reset effettuato con successo!')
                    else {
                        toast.error('Errore!')
                    }
                }
                ).catch((err) => {
                    console.log(err)
                })

                break;
            default:
                break;
        }
    }
    return (
        options2 && options2.length > 0 && !Object.is(lega, null) ?
            <div>
                <div className='text-center-surv edition-table-cont' >
                    <table class='surv-table-editions '>
                        <thead>
                            <th>Nome</th>
                            <th>Remove Cache</th>


                        </thead>
                        <tbody Style={'margin-top:20px'}>
                            <tr>
                                <td> RESET COMPLETO</td>
                                <td> <button className="cache_button" onClick={() => removeCache('reset')}>RIMUOVI</button></td>
                            </tr>
                            <tr>
                                <td> CONFIGURAZIONI</td>
                                <td> <button className="cache_button" onClick={() => removeCache('configuration')}>RIMUOVI</button></td>
                            </tr>

                            <tr>

                                <td> CLASSIFICHE</td>



                                <td> <button className="cache_button" onClick={() => removeCache('ranking')}>RIMUOVI</button></td>
                            </tr>
                            <tr>
                                <div className="w-100 text-center-surv flex home_cache">
                                    <td className="home_text_cache"> AGGIORNA CLASSIFICA</td>
                                    <Select className="w-60 surv-select select-lega-cache ml-5 mt-10" options={options2} onChange={setLegaValue} value={getLabel(lega)} placeholder={getLabel(lega)} />

                                </div>
                                <td> <button className="cache_button" onClick={() => removeCache('update_ranking')}>RIMUOVI</button></td>
                            </tr>
                            <tr>
                                <div className="w-100 text-center-surv flex home_cache">
                                    <td className="home_text_cache"> QUOTE</td>
                                    <input className="w-60 surv-select select-lega-cache ml-5 mt-10" type='text' onChange={handleQuote} value={matchdayId} />

                                </div>
                                <td> <button className="cache_button" onClick={() => removeCache('quote')}>RIMUOVI</button></td> </tr>
                            <tr>
                                <div className="w-100 text-center-surv flex home_cache">
                                    <td className="home_text_cache"> COMPETIZIONE</td>
                                    <input className="w-60 surv-select select-lega-cache ml-5 mt-10" type='text' onChange={handleEdition} placeholder={'id'} />

                                </div>
                                <td> <button className="cache_button" onClick={() => removeCache('competition')}>RIMUOVI</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ToastContainer theme="colored" />
            </div> : '')
}