
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch";
import { MultiSelect } from "react-multi-select-component";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import storage, { app } from "./FirebaseConfig.js"
import { GoogleAuthProvider, browserSessionPersistence, getAuth, inMemoryPersistence, setPersistence, signInWithPopup, signOut } from 'firebase/auth';
import Cookies from "universal-cookie";
export default function Edizioni(props) {
    const [editions, setEditions] = useState([])
    const [edition_info, setEditionInfo] = useState('')
    const [selected_edition, setSelectedEdition] = useState({})
    const [selected_edition_id, setSelectedEditionId] = useState(0)
    const [open_modal, setOpenModal] = useState(false)
    const [open_modal_code, setOpenModalCode] = useState(false)
    const [edit_view, setEditView] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [tmpPrize, setTmpPrize] = useState('')
    const [tmpName, setTmpName] = useState('')
    const [tmpType, setTmpType] = useState(true)
    const [tmpMaxLives, setTmpMaxLives] = useState('')
    const [tmpMaxUsers, setTmpMaxUsers] = useState('')
    const [titolo, setTitolo] = useState('')
    const [titoloMessaggio, setTitoloMessaggio] = useState('')
    const [message, setMessage] = useState('')
    const [boardMessage, setBoardMessage] = useState('')
    const [customMessage, setCustomMessage] = useState(false)
    const [boardNotification, setBoardNotification] = useState(false)
    const [hasAttachment, setHasAttachment] = useState(false)
    const [myOption, setMyOption] = useState([])
    const [selected, setSelected] = useState([])
    const [listener, setListener] = useState(false)
    const [sortBy, setSortBy] = useState('id')
    const [winnerMode, setWinnerMode] = useState(false)
    const [sent, setSent] = useState(false)
    const [codes, setCodes] = useState([])
    const [matchday, setMatchday] = useState(null)
    const [admins, setAdmins] = useState([])
    const [promoBonusLives, setPromoBonusLives] = useState('')
    const [promoMinFriends, setPromoMinFriends] = useState('')
    const [promoType, setPromoType] = useState('')
    const [isRegistrable, setIsRegistrable] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [resultPopup, setResultPopup] = useState([])
    const [openPopup, openResultPopup] = useState(false)
    const [toSend, setToSend] = useState('')
    const [confirmationPopup, setConfirmationPopup] = useState(false)
    const [boardPopup, setBoardPopup] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLoading, setFileLoading] = useState(false)
    const [id_user, setIdUser] = useState(null)
    const [percent, setPercent] = useState(0)
    const [actionValue, setActionValue] = useState(1)
    const [androidUrl, setAndroidUrl] = useState('')
    const [iosUrl, setIosUrl] = useState('')
    const [codeError, setCodeError] = useState(false)

    async function handleUpload(idMessage) {
        if (!uploadedFiles) {
            alert("Please choose a file first!")
        }
        uploadedFiles.map((f, i) => {


            const storageRef = ref(storage, `/editions/${selected_edition.id}/board/${idMessage}/${f.name}`)
            const uploadTask = uploadBytesResumable(storageRef, f);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => {
                    toast.error('File non caricati su firebase')
                    console.log(err)
                },
                () => {
                    // download url
                    // getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    //console.log(url);
                    //  });
                }
            );

        })

    }
    useEffect(() => {
        if (hasAttachment) {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            setPersistence(auth, browserSessionPersistence).then(() => {
                signInWithPopup(auth, provider)
                    .then((result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const user = result.user;
                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                    }).catch((error) => {
                        console.log(error)
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential = GoogleAuthProvider.credentialFromError(error);
                        // ...
                    });
            })
        }
    }, [hasAttachment])
    //const [open_edit_modal, setOpenEditModal] = useState(false)
    const getAdminEditions = async () => {
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/allEditions", requestOptions)
            .then(response => response.json())
            .then(result => {
                setEditions(result.response)
                setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
            });
        return res
    }
    useEffect(() => {
        getAdminEditions()

    }, [])
    const closeModal = () => {
        setOpenModal(false)
        setEditView(false)

        //setOpenEditModal(false)
    }
    const closeModalCode = () => {
        setOpenModalCode(false)
        setListener(false)
        setSent(false)
        setSelected([])
        setWinnerMode(false)
        setCustomMessage(false)
        setMessage('')
        setCodeError(false)
        //setOpenEditModal(false)
    }
    const codePopup = async (e, id, matchday) => {

        setListener(true)
        setSelectedEdition(e)
        setSelectedEditionId(e.id)
        setSent(false)
        getInfo(e, id, 'modal_code')

    }
    const openBoardPopup = async (e, id, matchday) => {
        setSelectedEdition(e)
        setSelectedEditionId(e.id)
        setSent(false)
        setBoardPopup(true)
    }
    const closeBoardPopup = () => {
        const auth = getAuth();

        signOut(auth).then(() => {
            console.log('logout')
        }).catch((error) => {
            // An error happened.
        });


        setBoardPopup(false)
        setTitoloMessaggio('')
        setBoardMessage('')
        setUploadedFiles([])
        setBoardNotification(false)
        setHasAttachment(false)
        setIdUser(null)

        let cookies = new Cookies();

        cookies.remove("__Secure-3PSIDCC", { path: '/' })
        cookies.remove("__Secure-1PSIDCC", { path: '/' })
        cookies.remove("__Secure-3PAPISID", { path: '/' })
        cookies.remove("__Secure-1PAPISID", { path: '/' })
        cookies.remove("__Secure-3PSID", { path: '/' })
        cookies.remove("__Secure-1PSID", { path: '/' })
        cookies.remove("SIDCC", { path: '/' })
        cookies.remove("SAPISID", { path: '/' })
        cookies.remove("APISID", { path: '/' })
        cookies.remove("AEC", { path: '/' })
        cookies.remove("SID", { path: '/' })
        cookies.remove("SSID", { path: '/' })
        cookies.remove("HSID", { path: '/' })
        cookies.remove("SEARCH_SAMESITE", { path: '/' })
        cookies.remove("CONSENT", { path: '/' })
        cookies.remove("NID", { path: '/' })
        cookies.remove("__Secure-ENID", { path: '/' })
    }
    const wouldSend = (e) => {
        setToSend(e)
        setConfirmationPopup(true)
    }
    const sendAdminVerification = async (e) => {
        openResultPopup(true)
        setLoading2(true)
        setSelectedEdition(e)
        setSelectedEditionId(e.id)
        let write = {

            idEdition: e.id,
            admin: true,
            title: "Approva Operazione Admin",
            message: "Fornisci questo codice per autorizzare l'operazione"

        }
        let toReturn = {
            body: write,
            path: '/survivor/writeMessage',
            httpMethod: 'POST',
            env: sessionStorage.getItem('env') === 'https://api.skillbol.com' ? 'PROD' : 'DEV',
            isBase64Encoded: false
        }

        fetch('https://nd3nnk6inlz75oovaaqdr2al5e0qqkcz.lambda-url.eu-west-2.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify(toReturn)
        }).then(response => response.json())
            .then(result => {
                setResultPopup(result.codes)

                setTimeout(() => {
                    setLoading2(false)
                }, 500);
            }).catch((err) => {
                console.log('error')
                setLoading1(false)
                toast.error(err.message)
            }).finally(() => {
                setConfirmationPopup(false)
            })


    }

    const getInfo = async (e, id, popup_status) => {

        setSelectedEdition(e)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/edition/" + id, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (popup_status === 'modal_code')
                    setOpenModalCode(true)
                else
                    setOpenModal(true)

                setEditionInfo(result.response)
                setTmpPrize(result.response.edition.prize)
                setTmpName(result.response.edition.name)
                setTmpType(result.response.edition.type === 'PRIVATE' ? false : true)
                setTmpMaxLives(result.response.edition.max_lives_user)
                setTmpMaxUsers(result.response.edition.max_users)
                setPromoBonusLives(result.response.edition.promoBonusLives)
                setPromoType(result.response.edition.promoType)
                setIsRegistrable(result.response.edition.isRegistrable)
                setPromoMinFriends(result.response.edition.promoMinFriends)
                setTitolo('Lega ' + result.response.edition.name + ' - ' + result.response.round + '° Giornata')
                setMatchday(result.response.idMatchday)
                setAdmins(result.response.uidAdmins)
                setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
            });
        return res
    }

    const save = () => {
        setLoading(true)
        let toEdit = {

            name: tmpName,
            prize: tmpPrize,
            max_lives_user: tmpMaxLives,
            max_users: tmpMaxUsers,
            id: selected_edition.id,
            isRegistrable: isRegistrable,
            promoInfo: promoType + (promoMinFriends ? '-' + promoMinFriends : '') + (promoBonusLives ? '-' + promoBonusLives : ''),
            type: !tmpType ? 'PRIVATE' : 'PUBLIC'
        }

        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/editEdition ', {
            method: 'POST',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            },
            body: JSON.stringify(toEdit)
        }).then(response => response.json())
            .then(result => {
                toast.success(result.message)
                setTimeout(() => {
                    getAdminEditions()

                }, 200);
            }).catch((err) => {
                console.log('error')
            })


        closeModal()
    }
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;  //time in ms, 5 seconds for example
    function add() {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }
    function clear() {
        clearTimeout(typingTimer);
    }
    function doneTyping() {
        let element = document.querySelector(' .testing > div > div.dropdown-content > div > div > div > input[type=text]')

        if (element && element.value) {
            let tosend = {
                search_text: element.value
            }
            getFilteredUserList(tosend)
        }

    }
    const setSelectedOption = (e) => {

        myOption.map((o, index) => {
            e.map((sel) => {
                if (sel.value == o.idUser)
                    myOption.splice(index, 1)
            })
        })
        setSelected(e)
    }
    useEffect(() => {


        if (listener) {
            //on keyup, start the countdown
            window.addEventListener("keyup", add, false);
            //on keydown, clear the countdown 
            window.addEventListener("keydown", clear, false)




            //user is "finished typing," do something

        } else {
            //on keyup, start the countdown
            window.removeEventListener("keyup", add, false);
            //on keydown, clear the countdown 
            window.removeEventListener("keydown", clear, false);

        }


        return () => {
            //on keyup, start the countdown
            window.removeEventListener("keyup", add, false);
            //on keydown, clear the countdown 
            window.removeEventListener("keydown", clear, false);

        }

    }, [listener])

    const getFilteredUserList = (tosend) => {
        var tmp = []
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/User/getAll?page=0&number=50&username=' + tosend.search_text + '&idEdition=' + selected_edition_id, {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            },

        }).then(response => response.json())
            .then(result => {
                let tmp = []
                let exist = false

                result.response.map((u) => {
                    exist = false
                    selected.map((sel) => {
                        if (sel.value == u.idUser)
                            exist = true
                    })
                    if (!exist) {
                        let o = {
                            label: u.username,
                            value: u.idUser
                        }
                        tmp.push(o)
                    }
                })

                setMyOption(tmp)
            }).catch((err) => {
                console.log('error')
            })

    }
    const orderBy = (type) => {
        setSortBy(type)
        setLoading(true)
        let tmp = []
        switch (type) {
            case 'id_crescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.id < b.id) { return -1; }
                    if (a.id > b.id) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;

            case 'id_decrescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.id > b.id) { return -1; }
                    if (a.id < b.id) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;

            case 'name_crescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;
            case 'name_decrescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;
            case 'status_decrescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.status.toLowerCase() > b.status.toLowerCase()) { return -1; }
                    if (a.status.toLowerCase() < b.status.toLowerCase()) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;
            case 'status_crescente':
                tmp = editions
                tmp.sort(function (a, b) {
                    if (a.status.toLowerCase() < b.status.toLowerCase()) { return -1; }
                    if (a.status.toLowerCase() > b.status.toLowerCase()) { return 1; }
                    return 0;
                })
                setEditions(tmp)
                setTimeout(() => {

                    setLoading(false)
                }, 1000);
                break;
        }

    }
    const unique = (array) => {
        var a = array.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i].value === a[j].value)
                    a.splice(j--, 1);
            }
        }

        return a;
    }
    const sendCode = () => {
        if (selected.length == 0 && !winnerMode)
            toast.warn('Non puoi inviare un messaggio senza selezionare i destinatari!')
        else {
            let tosend = []
            selected.map((s) => {
                tosend.push(s.value)
            })
            let body = {}
            if (winnerMode)
                body = {
                    idEdition: selected_edition.id,
                    title: titolo,
                    idMatchday: matchday
                }

            else {
                body = {
                    idEdition: selected_edition.id,
                    users: tosend,
                    title: titolo,
                    idMatchday: matchday
                }
                if (customMessage && message !== '')
                    body.message = message.replace('[code]', '%s')
            }
            let toReturn = {
                body: body,
                path: '/survivor/writeMessage',
                httpMethod: 'POST',
                env: sessionStorage.getItem('env') === 'https://api.skillbol.com' ? 'PROD' : 'DEV',
                isBase64Encoded: false
            }
            setCodeError(false)
            setLoading1(true)

            fetch("https://nd3nnk6inlz75oovaaqdr2al5e0qqkcz.lambda-url.eu-west-2.on.aws/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain'
                },
                redirect: 'follow',
                body: JSON.stringify(toReturn)
            })
                .then(response => response.json())
                .then(result => {
                    if (result && result.codes && result.codes.length > 0)
                        setCodes(result.codes)
                    else if (result && result.message) {
                        toast.success(result.message)
                    } else {
                        toast.success('Inviato!')
                    }
                    setTimeout(() => {
                        setLoading1(false)
                    }, 500);

                })
                .catch(error => {
                    setCodeError(true)
                    setLoading1(false)
                    toast.error(error.message)
                    console.log('error', error)
                }).finally(() => {

                    setSent(true)
                });


        }
    }
    const deleteEdition = () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Edition/' + selected_edition.id, {
            method: 'DELETE',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            },

        }).then(response => response.json()).then(result => {
            toast.success(result.message)
            setTimeout(() => {
                setDeleteModal(false)
                closeModal()
                getAdminEditions()

            }, 200);
        }).catch((error) => {
            toast.error(error.message)
        })

    }
    const uploadImage = (e) => {
        //  console.log(e.target.files)
        let v = []
        Array.prototype.forEach.call(e.target.files, function (file) { v.push(file) });
        setUploadedFiles(v)
        document.getElementById("menu-open-file").value = ' ';
        document.getElementById("menu-open-file").title = ' ';

    }
    const loadingFile = async (value) => {
        setFileLoading(value)
    }
    const removeFile = (index) => {
        loadingFile(true)
        setTimeout(() => {

            if (uploadedFiles && uploadedFiles.length > 0) {
                let uf = uploadedFiles
                uf.splice(index, 1)
                setUploadedFiles(uf)
                loadingFile(false)

            }
        }, 500);
    }
    const createBoardMessage = () => {
        let to_create = {
            title: titoloMessaggio,
            text: boardMessage,
            hasAttachment: hasAttachment,
            idUser: id_user,
            action: parseInt(actionValue),
            androidUrl: actionValue == 1 ? androidUrl : null,
            iosUrl: actionValue == 1 ? iosUrl : null
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Edition/' + selected_edition_id + '/postMessage?sendNotification=' + boardNotification, {
            method: 'PUT',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            },
            body: JSON.stringify(to_create)
        }).then(response => response.json())
            .then(result => {
                if (result.server == 200) {
                    handleUpload(result.response).then(() => {
                        closeBoardPopup(false)
                        toast.success('Messaggio creato correttamente')
                    })
                } else {
                    toast.error('Qualcosa è andato storto!')
                }
            })
    }
    const handleChange = (e, type) => {

        switch (type) {
            case 'prize':
                setTmpPrize(e.target.value)
                break;
            case 'name':
                setTmpName(e.target.value)
                break;
            case 'lives':
                setTmpMaxLives(parseInt(e.target.value))
                break;
            case 'users':
                setTmpMaxUsers(parseInt(e.target.value))
                break;
            case 'type':
                setTmpType(!tmpType)
                break;
            case 'titolo':
                setTitolo(e.target.value)
                break;
            case 'titolo_messaggio':
                setTitoloMessaggio(e.target.value)
                break;
            case 'message':
                setMessage(e.target.value)
                break;
            case 'promo_bonus_lives':
                setPromoBonusLives(e.target.value)
                break;
            case 'promo_min_friends':
                setPromoMinFriends(e.target.value)
                break;
            case 'promo_type':
                setPromoType(e.target.value)
                break;
            case 'is_registrable':
                setIsRegistrable(!isRegistrable)
                break;
            case 'board_message':
                setBoardMessage(e.target.value)
                break;
            case 'id_user':
                setIdUser(e.target.value)
                break;
            case 'androidUrl':
                setAndroidUrl(e.target.value)
                break;
            case 'iosUrl':
                setIosUrl(e.target.value)
                break;
            default:
                break;
        }
    }
    let editions_values = ''
    if (editions.length > 0)
        editions_values = editions.map((e) => <tr>
            <td Style={'place-content: center;'}> <div className={"mode-" + e.mode + "_ico team_ico"}></div></td>
            <td Style={'place-content: center;'}>{e.id}</td>
            <td Style={'place-content: center;'}>{e.registrationCode}</td>
            <td Style={'place-content: center; max-width:200px; width:22%; overflow:hidden;white-space:nowrap; text-overflow: ellipsis;'}>{e.name}</td>
            <td Style={'place-content: center;'}>{e.maxUsers}</td>
            <td Style={'place-content: center;'}>{e.league.name}</td>

            <td Style={'place-content: center;'}>{e.status}</td>
            <td Style={'place-content: center;'}>{e.type === 'PRIVATE' ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-lock-fill" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-unlock-fill" viewBox="0 0 16 16">
                <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
            </svg>}</td>
            <td className='flex action-svg' Style={'place-content: center;'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-info-circle surv-svg" viewBox="0 0 16 16" onClick={() => getInfo(e, e.id, 'modal_info')}>
                    <title>Gestione Edizione</title>   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>

                {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="red" class="bi bi-x-square surv-svg" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
*/}
                {e.status !== 'PRE' ? <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-trophy  surv-svg" viewBox="0 0 16 16" onClick={() => codePopup(e, e.id, e.matchday)}>
                    <title>Messaggio premiazioni</title> <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z" />
                </svg> : <div className='empty_svg'></div>}
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-shield-check surv-svg" viewBox="0 0 16 16" onClick={() => wouldSend(e)}>
                    <title>Richiedi autorizzazione admin</title>
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                    <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-file-text surv-svg" viewBox="0 0 16 16" onClick={() => openBoardPopup(e, e.id, e.matchday)}>
                    <title>Crea Messaggio Bacheca</title>
                    <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                </svg>
            </td>
        </tr >)
    let customModalStylesTracking = {

        content: {
            background: '#1c3144',

            width: '40%',
            height: '80vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '6%',
            position: 'relative'
        },

    };
    let customModalStylesConfirmation = {

        content: {
            background: '#1c3144',

            width: '30%',
            height: '30vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '10%',
            position: 'relative'
        },

    };
    let customModalStylesBoard = {

        content: {
            background: '#1c3144',

            width: '60%',
            height: '70vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '10%',
            position: 'relative'
        },

    };
    let admin_list = ''
    if (admins)
        admin_list = admins.map((admin, index) => <React.Fragment>{admin}{index == admins.length - 1 ? '' : ', '}</React.Fragment>)
    let all_selected_string = 'z'
    all_selected_string = selected.map((s, index) => { return index == 0 ? s.label : ',' + s.label })
    let codes_text = ''
    if (codes && codes.length > 0)
        codes_text = codes.map((c) => <div>{c}</div>)
    let resultPopupCodes = ''
    if (resultPopup)
        resultPopupCodes = resultPopup.map((c) => <div>{c}</div>)
    let uploaded_files = ''
    if (uploadedFiles && uploadedFiles.length > 0)
        uploaded_files = uploadedFiles.map((u, index) => <div className='flex' Style={'align-items:center'} ><div Style={'color: white;font-family: Comic Sans MS, Comic Sans; margin-left: 3%; font-size: 16px;width: 25%;'}>{u.name}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16" onClick={() => removeFile(index)}>
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg></div >)
    return (
        <React.Fragment>
            <div className='text-center-surv edition-table-cont' >

                <table class='surv-table-editions '>
                    <thead>
                        <th></th>
                        <th Style={'font-size:20px;'}>Id{sortBy === 'id_decrescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-up ml-3" viewBox="0 0 16 16" onClick={() => orderBy('id_crescente')}>
                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                        </svg> : (sortBy === 'id_crescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-arrow-down ml-3" viewBox="0 0 16 16" onClick={() => orderBy('id_decrescente')}>
                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-bar-chart ml-3" viewBox="0 0 16 16" onClick={() => orderBy('id_crescente')}>
                            <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z" />
                        </svg>)}</th>
                        <th Style={'font-size:20px;'}>Reg. Code</th>

                        <th Style={'font-size:20px;'}>Nome {sortBy === 'name_decrescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-up ml-3" viewBox="0 0 16 16" onClick={() => orderBy('name_crescente')}>
                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                        </svg> : (sortBy === 'name_crescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-arrow-down ml-3" viewBox="0 0 16 16" onClick={() => orderBy('name_decrescente')}>
                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-bar-chart ml-3" viewBox="0 0 16 16" onClick={() => orderBy('name_crescente')}>
                            <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z" />
                        </svg>)}
                        </th>
                        <th Style={'font-size:20px'}>Utenti</th>
                        <th Style={'font-size:20px'}>Lega</th>


                        <th Style={'font-size:20px;'}>  Status {sortBy === 'status_decrescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-up ml-3" viewBox="0 0 16 16" onClick={() => orderBy('status_crescente')}>
                            <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                        </svg> : (sortBy === 'status_crescente' ? <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-arrow-down ml-3" viewBox="0 0 16 16" onClick={() => orderBy('status_decrescente')}>
                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-bar-chart ml-3" viewBox="0 0 16 16" onClick={() => orderBy('status_crescente')}>
                            <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z" />
                        </svg>)}</th>
                        <th Style={'font-size:20px'}>Type</th>
                        <th Style={'font-size:20px'}>Azioni</th>
                    </thead>
                    <tbody Style={'margin-top:20px'}>
                        {!loading ? editions_values : <div className="loader_cont"><div className="loader"></div></div>}
                    </tbody>
                </table>
            </div>
            <Modal
                isOpen={open_modal}
                onAfterOpen={null}
                onRequestClose={() => closeModal()}
                style={customModalStylesTracking}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}
            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Edition </div>


                        <div className="close_button flex" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-trash3 edit_field" viewBox="0 0 16 16" onClick={() => setDeleteModal(true)}>
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-pencil edit_field" viewBox="0 0 16 16" onClick={() => setEditView(!edit_view)}>
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                            <svg onClick={() => closeModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className='modal_content'>

                        <div className='modal_p_title'>
                            Nome
                        </div>

                        {!edit_view ? <div className='admin_text'>{selected_edition.name}</div> :
                            <input className='admin_text input_text' type='text' value={tmpName ? tmpName : '-'} onChange={(e) => handleChange(e, 'name')} />}
                        <div className='modal_p_title'>
                            Admin
                        </div>

                        <div className='admin_text'>{admin_list}</div>
                        <div className='modal_p_title'>
                            Premio
                        </div>


                        {!edit_view ? <div className='admin_text'>{selected_edition.prize ? selected_edition.prize : '-'}</div> :
                            <input className='admin_text input_text' type='text' value={tmpPrize ? tmpPrize : '-'} onChange={(e) => handleChange(e, 'prize')} />}
                        <div className='modal_p_title'>
                            Limite vite utente
                        </div>
                        {!edit_view ? <div className='admin_text'>{edition_info.edition ? edition_info.edition.max_lives_user : 'Nessun Limite'}</div>
                            : <input className='admin_text input_text' type='text' value={tmpMaxLives ? tmpMaxLives : '-'} onChange={(e) => handleChange(e, 'lives')} />}
                        <div className='modal_p_title'>
                            Limite numero utenti
                        </div>
                        {!edit_view ? <div className='admin_text'>{edition_info.edition ? (edition_info.edition.max_users ? edition_info.edition.max_users : 'Nessun Limite')
                            : 'Nessun Limite'}</div>
                            : <input className='admin_text input_text' type='text' value={tmpMaxUsers ? tmpMaxUsers : '-'} onChange={(e) => handleChange(e, 'users')} />}
                        <div className='modal_p_title'>
                            Type
                        </div>

                        <div className='admin_text '>
                            <Switch onChange={(e) => handleChange(e, 'type')} checked={tmpType} checkedIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock-fill" viewBox="0 0 16 16">
                                <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                            </svg>} uncheckedIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill " viewBox="0 0 16 16">
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                            </svg>} offColor={'#c20000'} disabled={!edit_view ? true : false} />

                        </div>
                        <div className='admin_text '>
                            {!tmpType ? 'Private' : 'Public'}
                        </div>
                        <div className='modal_p_title'>
                            Totale vite
                        </div>
                        <div className='admin_text'>{edition_info.edition && edition_info.edition.total_lives ? edition_info.edition.total_lives : '0'}</div>
                        <div className='modal_p_title'>
                            Totale utenti
                        </div>
                        <div className='admin_text'>{edition_info.edition && edition_info.edition.total_users ? edition_info.edition.total_users : '0'}</div>
                        <div className='modal_p_title'>
                            Bonus
                        </div>
                        {!edit_view ? '' : <>
                            <div className='admin_text'>N vite invitante</div>
                            <div className='admin_text'>N vite invitante = N vite invitato</div>
                            <div className='admin_text'>Bonus code invitante</div>
                            <div className='admin_text'>Bonus code invitante = Bonus Code invitato</div></>}
                        {!edit_view ? <div className='admin_text'>{edition_info.edition && edition_info.edition.promoBonusLives ? edition_info.edition.promoBonusLives : ''}</div>
                            : <input className='admin_text input_text' type='text' value={promoBonusLives ? promoBonusLives : '-'} onChange={(e) => handleChange(e, 'promo_bonus_lives')} />}
                        <div className='modal_p_title'>
                            Amici da Portare
                        </div>
                        {!edit_view ? <div className='admin_text'>{edition_info.edition && edition_info.edition.promoMinFriends ? edition_info.edition.promoMinFriends : ''}</div>
                            :
                            <input className='admin_text input_text' type='text' value={promoMinFriends ? promoMinFriends : '-'} onChange={(e) => handleChange(e, 'promo_min_friends')} />
                        }
                        <div className='modal_p_title'>
                            Promo Type
                        </div>
                        {!edit_view ? '' : <> <div className='admin_text'>0 - No promo</div>
                            <div className='admin_text'>1 - Vita a chi invita</div>
                            <div className='admin_text'>2 - Vita ad entrambi</div>
                            <div className='admin_text'>3 - Bonus a chi invita</div>
                            <div className='admin_text'>4 - Bonus ad entrambi</div></>}

                        {!edit_view ? <div className='admin_text'>{edition_info.edition && edition_info.edition.promoType && edition_info.edition.promoType === '0' ? 'No Promo' : (edition_info.edition && edition_info.edition.promoType && edition_info.edition.promoType === '1' ? 'Porta un amico' : '---')}</div>
                            : <input className='admin_text input_text' type='text' value={promoType ? promoType : ''} onChange={(e) => handleChange(e, 'promo_type')} />}
                        <div className='modal_p_title'>
                            Is Registrable
                        </div>

                        <div className='admin_text '>
                            <Switch onChange={(e) => handleChange(e, 'is_registrable')} checked={isRegistrable} offColor={'#c20000'} disabled={!edit_view ? true : false} />

                        </div>

                    </div>

                    {edit_view ? <div className='save_button_cont' onClick={save}>
                        <button className='save_button'>SAVE</button>
                    </div> : ''}
                </div >
            </Modal >
            <Modal
                isOpen={open_modal_code}
                onAfterOpen={null}
                onRequestClose={() => closeModalCode()}
                style={customModalStylesTracking}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}
            >
                {!sent ?
                    <React.Fragment>
                        <div className="modal_content_container" Style={'height:auto'}>
                            <div className="modal_header flex">

                                <div className="modal_content_title">Invia codice </div>



                                <div className="close_button flex" >

                                    <svg onClick={() => closeModalCode()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                            <br />
                            <div className="surv-text-white fs-20 ml-20">{selected_edition && selected_edition.name ? selected_edition.name : ''} </div>
                            <br />
                            <div className='admin_text'>Titolo</div>
                            <input className='admin_text input_text' type='text' value={titolo} onChange={(e) => handleChange(e, 'titolo')} />
                            <br />

                            <hr Style={'color:white'} />

                            <div className='flex  mt-20'>
                                <div className='admin_text_message'>Scelta utenti </div>
                                <Switch onChange={(e) => setWinnerMode(!winnerMode)} checked={winnerMode} offColor={'#008800'} checkedIcon={''} uncheckedIcon={''} />
                                <div className='admin_text_message' Style={'margin-left: 22%;'}>Vincitori </div>

                            </div>
                            <br />
                            <div Style={'min-height: 80px;'}>
                                <div className='flex'>
                                    <div className="surv-text-white fs-20 ml-20 w-50">{!winnerMode ? 'Seleziona gli utenti' : ''} </div>
                                    <div className="surv-text-white fs-20 ml-20 w-50">{winnerMode ? 'Tutti gli utenti ancora in vita' : ''} </div>
                                </div>
                                {!winnerMode ?
                                    <div className='modal_content ml-20'>
                                        <MultiSelect
                                            id='select'
                                            className={'testing w-50'}
                                            options={myOption && myOption.length > 0 ? unique(selected.concat(myOption)) : []}
                                            value={selected}
                                            onChange={setSelectedOption}
                                            labelledBy="Select"
                                            overrideStrings={{
                                                selectSomeItems: "Select .",
                                                allItemsAreSelected: all_selected_string,
                                                selectAll: "Select All",
                                                search: "Search",
                                            }}
                                            isCreatable={false}
                                            hasSelectAll={false} />

                                    </div> : ''}
                            </div>
                            <hr Style={'color:white'} />
                            <div className='flex  mt-20'>
                                <div className='admin_text_message'>Messaggio Personalizzato</div>
                                <Switch onChange={(e) => setCustomMessage(!customMessage)} checked={customMessage} offColor={'#c20000'} checkedIcon={''} uncheckedIcon={''} />
                            </div>
                            {customMessage ?
                                <React.Fragment>
                                    <div className='admin_text_message_code mt-20' Style={'margin-left: 3%;'}>*Usa [code] per identificare la posizione del codice nel testo.  </div>
                                    <input className='admin_text input_text ' type='text' value={message} onChange={(e) => handleChange(e, 'message')} />
                                    <div className='admin_text_message mt-20'>Anteprima messaggio</div>
                                    <div className='admin_text_message_code_preview ' Style={'margin-left: 3%;padding: 2%;'}> {message.replace('[code]', '1a2b3c')}  </div>

                                </React.Fragment> : ''}

                            <br />
                        </div>
                        <div className=' text-center-surv bottom_position'>
                            <button className='button send_button' onClick={() => sendCode()} > Invia</button>
                        </div></React.Fragment>
                    : <React.Fragment>
                        <div className="modal_content_container" Style={'height:auto'}>
                            <div className="modal_header flex">

                                <div className="modal_content_title"> {sent && !codeError ? 'Codice inviato' : 'Errore'} </div>



                                <div className="close_button flex" >

                                    <svg onClick={() => closeModalCode()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {sent && !codeError ? <div className="modal_content_container" Style={'height:auto'}>
                            <div className="surv-text-white fs-20 ml-20">
                                Il messaggio è stato invitato con successo
                            </div>
                            <br />
                            {!loading1 ?
                                <div className="surv-text-white fs-20 ml-20" Style={'height: 53vh;    overflow: auto;'}>{codes_text} </div>
                                : <div className="loader_cont"><div className="loader"></div></div>}</div>
                            : (!loading1 && codeError ? <div className="modal_content_container" Style={'height:auto'}>
                                <div className="surv-text-white fs-20 ml-20">
                                    Qualcosa è andato storto durante l'invio del codice. Ritenta!
                                </div></div> : <div className="loader_cont"><div className="loader"></div></div>)}
                    </React.Fragment>
                }
            </Modal>
            <Modal
                isOpen={deleteModal}
                onAfterOpen={null}
                onRequestClose={() => deleteModal(false)}
                style={customModalStylesTracking}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}

            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Elimina Edizione </div>


                        <div className="close_button flex" >


                            <svg onClick={() => setDeleteModal(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal_content">
                        <div className="surv-text-white flex">
                            <div className="modal_content_title" Style={'margin-top:40px'}>
                                Vuoi davvero eliminare questa edizione?
                            </div>
                        </div>
                        <div className="surv-text-white flex">
                            <div className="modal_content_title">
                                <div Style={'margin-top:30px'}>

                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => deleteEdition()}>YES</button>
                                    <button className='save_button' onClick={() => setDeleteModal(false)}>NO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={openPopup}
                onAfterOpen={null}
                onRequestClose={() => openResultPopup(false)}
                style={customModalStylesTracking}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}
            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Admin </div>


                        <div className="close_button flex" >

                            <svg onClick={() => openResultPopup(false)()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className='modal_content'>

                    </div>   {!loading2 ? <div className="modal_content">
                        <div className="surv-text-white ">{resultPopupCodes}</div>
                    </div> : <div className="loader_cont"><div className="loader"></div></div>}</div></Modal>
            <Modal
                isOpen={confirmationPopup}
                onAfterOpen={null}
                onRequestClose={() => setConfirmationPopup(false)}
                style={customModalStylesConfirmation}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}

            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Codice Admin </div>


                        <div className="close_button flex" >


                            <svg onClick={() => setConfirmationPopup(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal_content">
                        <div className="surv-text-white flex">
                            <div className="modal_content_title" Style={'margin-top:40px'}>
                                Inviare il codice di conferma agli admin di questa edizione?
                            </div>
                        </div>
                        <div className="surv-text-white flex">
                            <div className="modal_content_title">
                                <div Style={'margin-top:30px'}>

                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => sendAdminVerification(toSend)}>Si</button>
                                    <button className='save_button' onClick={() => setConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={boardPopup}
                onAfterOpen={null}
                onRequestClose={() => closeBoardPopup(false)}
                style={customModalStylesBoard}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}

            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Crea Messaggio Bacheca </div>


                        <div className="close_button flex" >


                            <svg onClick={() => closeBoardPopup(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className="modal_content">
                        <div className="surv-text-white flex">
                            <div className="modal_content_title" Style={'margin-top:10px'}>
                                <div className='admin_text' >ID Utente</div>
                                <input className='admin_text ' Style={'width:100px;color:black'} type='number' value={id_user} onChange={(e) => handleChange(e, 'id_user')} />
                                <div className='admin_text' >Titolo</div>
                                <input className='admin_text input_text' type='text' value={titoloMessaggio} onChange={(e) => handleChange(e, 'titolo_messaggio')} />
                                <div className='admin_text' Style={'margin-top:20px'}>Messaggio</div>
                                <textarea className='admin_text input_text' rows={6} value={boardMessage} onChange={(e) => handleChange(e, 'board_message')}></textarea>
                                <div className='admin_text' Style={'margin-top:20px'}>Action</div>
                                <select className='admin_text input_text' Style={'width:200px;color:black'} value={actionValue} onChange={(e) => setActionValue(e.target.value)}>
                                    <option value={0}>O - Fullscreen</option>
                                    <option value={1}>1 - ExternalURL</option>
                                </select>
                                {actionValue == 1 ? <React.Fragment>
                                    <div className='admin_text' Style={'margin-top:20px'}>Android url</div>
                                    <input className='admin_text input_text' type='text' value={androidUrl} onChange={(e) => handleChange(e, 'androidUrl')} />
                                    <div className='admin_text' Style={'margin-top:20px'}>Ios url</div>
                                    <input className='admin_text input_text' type='text' value={iosUrl} onChange={(e) => handleChange(e, 'iosUrl')} />
                                </React.Fragment> : ''}
                                <div className='admin_text' Style={'margin-top:20px'}>Invia notifiche</div>
                                <Switch className='admin_text' onChange={(e) => setBoardNotification(!boardNotification)} checked={boardNotification} offColor={'#c20000'} checkedIcon={''} uncheckedIcon={''} />
                                <div className='admin_text' Style={'margin-top:20px'}>Attachment</div>
                                <Switch className='admin_text' onChange={(e) => setHasAttachment(!hasAttachment)} checked={hasAttachment} offColor={'#c20000'} checkedIcon={''} uncheckedIcon={''} />
                                {hasAttachment ? <input title="upload" value='' id='menu-open-file' className='admin_text' type='file' Style={'margin-top:20px; width: 95px;'} accept='image/png, image/jpeg' multiple={true} onChange={(e) => uploadImage(e)} />
                                    : ''}
                                {hasAttachment && !fileLoading ? uploaded_files : (fileLoading ? <div className="loader_cont"><div className="loader"></div></div> : '')}
                            </div>
                        </div>
                        <div className="surv-text-white flex">
                            <div className="modal_content_title">
                                <div Style={'margin-top:30px'}>
                                    <button className='admin_text save_button' Style={'margin-right:30px'} onClick={() => createBoardMessage()}>Crea</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ToastContainer theme="colored" />
        </React.Fragment >
    )
}