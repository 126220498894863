import React, { useEffect } from "react";
import Select from 'react-select'
import axios from "axios";
import { Stepper } from 'react-form-stepper';
import RowMatch from "./RowMatch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ChiusuraMatch(props) {
    const [lega, setLega] = React.useState(null);
    const [tmp_disabled, setTmpDisabled] = React.useState(true);
    const [matchday_status, setStatus] = React.useState(null);
    const [matchday_id, setMatchdayId] = React.useState(null);
    const [matches, setMatches] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [options2, setOptions2] = React.useState([]);
    const setLeagues = (data) => {
        let obj = []
        data.map((l, i) => {
            obj.push({ value: l.idLeague, label: l.name })
        })
        setOptions2(obj)
    }
    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })
            .then(response => response.json())
            .then(result => {
                setLeagues(result.response)
                if (result.response.length > 0) {
                    setLega(result.response[0].idLeague)
                    getGiornata(result.response[0].idLeague)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const sendResult = () => {
        setLoading(true)
        setTmpDisabled(true)
        let toSend = []
        matches.map((match) => {
            if (match.just_inserted)
                toSend.push({
                    homeTeam: match.homeTeam.name,
                    homeScore: match.homeScore,
                    awayTeam: match.awayTeam.name,
                    awayScore: match.awayScore,
                    matchday: matchday_id
                })
        })


        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.defaults.headers.common['appVersion'] = '1000';
        axios.put(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/closeMatch?idLeague=" + lega, toSend).then(function (response) {


            toast.success('Risultati aggiornati correttamente!')
            setLoading(false)
            getGiornata(lega)

        }).catch(error => {
            toast.error('Qualcosa è andato storto')
            console.log('error', error)
            setLoading(false)
        });
    }
    const getGiornata = (val) => {
        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/Matchday/currentHome?idLeague=" + val).then(function (response) {
            setMatches(response.data.response.matches)
            setStatus(response.data.response.status)
            setMatchdayId(response.data.response.matchdayId)
            setLoading(false)
        }).catch(error => {
            console.log('error', error)
            toast.error('Qualcosa è andato storto')
            setLoading(false)
            setMatches([])
        });
    }
    const getTranslation = () => {
        axios.defaults.headers.common['jwt'] = sessionStorage.getItem('val');
        axios.get(sessionStorage.getItem('env') + "/survivor/api/v1/Team/getTranslations").then(function (response) {
            localStorage.setItem('translations', JSON.stringify(response.data.response))

            setLoading(false)
        }).catch(error => {
            console.log('error', error)
            setLoading(false)
        });
    }
    const addResult = (m, home_score, away_score) => {
        setLoading(true)
        let tmp = matches
        tmp.map((match) => {
            if (match.homeTeam.name === m.homeTeam.name) {
                match.homeScore = home_score
                match.awayScore = away_score
                match.just_inserted = true
                setTmpDisabled(false)
            }
            setMatches(tmp)
        })
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }
    useEffect(() => {
        if (!localStorage.getItem('translations'))
            getTranslation()

        getLeghe()
    }, [])
    const setLegaValue = (val) => {
        setLega(val.value)
        getGiornata(val.value)
    }
    const removeResult = (m) => {
        setLoading(true)
        let tmp = matches
        let cont = 0
        tmp.map((match) => {
            if (match.homeTeam.name === m.homeTeam.name) {
                match.homeScore = null
                match.awayScore = null
                match.just_inserted = null
            }
            if (match.just_inserted == true) {
                cont++
            }

        })
        if (cont == 0)
            setTmpDisabled(true)
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }
    const getLabel = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.label
        })
        return toReturn
    }
    const getValue = (lega) => {
        let toReturn = ''
        options2.map((o) => {
            if (lega == o.value)
                toReturn = o.value
        })
        return toReturn
    }
    let matchesInput = 'w'
    matchesInput = matches.map((m) => <React.Fragment> {(m.awayScore === null && m.homeScore === null) ? <RowMatch match={m} addResult={addResult} /> : ''}</React.Fragment>)

    let inserted = ''
    inserted = matches.map((m) => <React.Fragment>  {(!m.awayScore && !m.homeScore && matchday_status === 'PRE') || (matchday_status !== 'PRE' && m.awayScore !== null && m.homeScore !== null) && !m.just_inserted ?
        <tr>
            <td>
                {m.homeTeam.name}
            </td>
            {matchday_status === 'PRE' ? '' : <td>
                {m.homeScore}
            </td>}
            {matchday_status === 'PRE' ? '' : <td>
                {m.awayScore}
            </td>}
            <td>
                {m.awayTeam.name}
            </td>
            {matchday_status === 'PRE' || (matchday_status !== 'PRE' && m.awayScore !== null && m.homeScore !== null) ? '' : <td>
                <button className='button'> Add</button></td>}
        </tr>
        : ''}
    </React.Fragment>
    )

    let just_inserted = ''
    just_inserted = matches.map((r) => <React.Fragment>
        {r.just_inserted == true ? <tr>
            <td>
                {r.homeTeam.name}
            </td>
            {matchday_status === 'PRE' ? '' : <td>
                {r.homeScore}
            </td>}
            {matchday_status === 'PRE' ? '' : <td>
                {r.awayScore}
            </td>}
            <td>
                {r.awayTeam.name}
            </td>
            {matchday_status === 'PRE' ? '' : <td>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x-circle ml-10 pointer" viewBox="0 0 16 16" onClick={() => removeResult(r)} >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg></td>}
        </tr> : ''}
    </React.Fragment>)

    let config = {
        activeBgColor: "orange",
        activeTextColor: "orange",
        completedBgColor: "green",
        completedTextColor: "green",
        inactiveBgColor: "grey",
        inactiveTextColor: "grey"
    };
    let connectorConfig = {
        completedColor: 'green'
    }
    let config2 = {
        activeBgColor: "red",
        activeTextColor: "red",
        completedBgColor: "red",
        completedTextColor: "red",
        inactiveBgColor: "red",
        inactiveTextColor: "red"
    };

    if (loading || Object.is(lega, null))
        return (<div className="loader_cont"><div className="loader"></div></div>)
    else if (options2.length > 0)
        return (<div className="w-100 close_matches_page">
            <div className="w-100 text-center-surv mb-10">

                <Select className="w-60 surv-select mt-10" options={options2} onChange={(e) => setLegaValue(e)} value={getValue(lega)} placeholder={getLabel(lega)} />
            </div>

            <div className="text">Stato corrente: </div>

            <div className="text-center-surv">
                {matchday_status === 'PRE' ? <Stepper
                    connectorStateColors={true}
                    steps={[{ label: 'PRE', active: true }, { label: 'ONGOING', active: false, }, { label: 'POST', active: false, }]}
                    styleConfig={config}
                />
                    : (matchday_status === 'ONGOING' ?
                        <Stepper
                            steps={[{ label: 'PRE', completed: true }, { label: 'ONGOING', completed: true, }, { label: 'POST', active: false }]}
                            styleConfig={config}
                            connectorStateColors={true}
                            connectorStyleConfig={connectorConfig}
                        />
                        :
                        <Stepper
                            steps={[{ label: 'PRE', completed: true }, { label: 'ONGOING', completed: true, }, { label: 'POST', completed: true }]}
                            styleConfig={config2}
                            connectorStateColors={true}
                        />
                    )}
            </div>


            {/* {inserted}*/}
            <div className="text-center-surv ">
                <table className={matchday_status === 'PRE' ? "surv-table_s" : "surv-table"}>

                    <tbody>
                        {matchday_status === 'ONGOING' ? just_inserted : ''}
                        {inserted}
                        {matchday_status === 'ONGOING' ? matchesInput : ''}
                    </tbody>
                </table>
            </div>

            {matchday_status === 'PRE' ? '' : <button className={tmp_disabled ? 'disabled_button' : 'button'} onClick={() => sendResult()} disabled={tmp_disabled ? tmp_disabled : false}> Invia</button>}
            <ToastContainer theme="colored" />
        </div >)
    else
        return ('')
}