import React, { useEffect } from "react"
import Select from 'react-select'
export default function Login(props) {
    const [input, setInput] = React.useState('')
    const [env, setEnv] = React.useState('https://api.skillbol.com')
    // const [env, setEnv] = React.useState(1)
    const [visible, setVisible] = React.useState(false)
    const set = (e) => {
        setInput(e.target.value)
    }
    const options2 = [
        { value: 'https://survivorgame-dev.ddns.net', label: 'DEV' },
        //{ value: 'http://127.0.0.1:8080', label: 'LOCAL' },
        { value: 'https://api.skillbol.com', label: 'PROD' }
    ]
    const getLabel = (val) => {
        options2.map((o) => {
            if (o.value === env)
                return o.label
        })
    }
    const setEnvironment = (e) => {
        setEnv(e.value)
    }
    return (<div className="login_page">
        <img className="logo" src='https://skillbol.com/images/logo_text_500.png' />
        <div className="sub_logo  surv-text">Operations</div>
        <div className="center_login">
            <div className='surv-text w-100 mt-20 mb-10'>
                <div className="login_text">Inserisci codice d'accesso!</div>
            </div>
            <div className="mt-20 ">
                <input className="blu_text" type={visible ? 'text' : 'password'} value={input} onChange={(e) => set(e)} />
                {!visible ? <svg onClick={() => setVisible(true)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill ml--20" viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg> : <svg onClick={() => setVisible(false)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill ml--20" viewBox="0 0 16 16">
                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>}

            </div>

            <div className="w-100 text-center-surv ">
                <Select className="w-60 surv-select mt-10" options={options2} onChange={(e) => setEnvironment(e)} value={getLabel(env)} placeholder={getLabel(env)} />
            </div>

            <button className='login_button ' onClick={() => props.login(input, env)} > Login</button>
        </div>
    </div>)
}