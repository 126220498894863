import React from 'react';
import { useState } from 'react';
import Select from 'react-select'
import DateTimeComponent from './DateTimeComponent';
export default function RowMatchDate(props) {
    const [value, setValue] = useState(null)
    const handleChange = (e) => {
        setValue(e)
        props.setMatchDate(props.match, e)
    }
    const handleChangeHomePerc = (e) => {
        props.setHomePerc(props.match, e.target.value)
    }
    const handleChangeHomePoints = (e) => {
        props.setHomePoints(props.match, e.target.value)
    }
    const handleChangeAwayPerc = (e) => {

        props.setAwayPerc(props.match, e.target.value)
    }
    const handleChangeAwayPoints = (e) => {

        props.setAwayPoints(props.match, e.target.value)
    }

    return (
        <tr className='open_row'>
            <td><div Style={'padding-top:10px'}>
                {props.match.homeTeam.name}</div>
                <div Style={'padding-bottom:10px'}><input className="home_perc" type={'text'} value={props.match.homePerc} placeholder='homePerc' onChange={handleChangeHomePerc} /></div>

                <div><input className="home_perc" type={'text'} value={props.match.homePoints} placeholder='homePoints' onChange={handleChangeHomePoints} /></div>
            </td>


            <td>
                <div Style={'padding-top:10px'}>
                    {props.match.awayTeam.name}</div>
                <div Style={'padding-bottom:10px'} ><input className="home_perc" type={'text'} value={props.match.awayPerc} placeholder='awayPerc' onChange={handleChangeAwayPerc} />    </div>
                <div><input className="home_perc" type={'text'} value={props.match.awayPoints} placeholder='awayPoints' onChange={handleChangeAwayPoints} />    </div>

            </td>
            <td >
                <DateTimeComponent value={new Date(props.match.date)} setMatchDate={props.setMatchDate} handleChange={handleChange} />
            </td>


        </tr>
    )
}
