import React from "react";
import ApriGiornata from "./ApriGiornata";
import ChiudiGiornata from "./ChiudiGiornata";
import ChiusuraMatch from "./ChiusuraMatch";
import Edizioni from "./Edizioni";
import Headers from "./Header";
import Cache from "./Cache";
import Configurations from "./Configurations";
import Dashboard from "./Dashboard";
import Leghe from "./Leghe";
import Notifiche from "./Notifiche";

export default function Homepage(props) {
    const [active, setActive] = React.useState('dashboard');
    return (<div>
        <Headers active={active} setActive={setActive} />

        {active === 'chiudi_match' ? <ChiusuraMatch /> : (
            active === 'chiudi_giornata' ? <ChiudiGiornata />
                : (active === 'apri_giornata' ? <ApriGiornata /> :
                    (active === 'edizioni' ? <Edizioni /> :
                        (active === 'cache' ? <Cache /> :
                            (active === 'configurations' ? <Configurations /> :
                                (active === 'leghe' ? <Leghe /> :
                                    (active === 'notifiche' ? <Notifiche /> :
                                        (active === 'dashboard' ? <Dashboard /> : ''))))))))}
    </div>)

}