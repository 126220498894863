import { useEffect, useState } from "react"
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch";
import { MultiSelect } from "react-multi-select-component";
import Modal from 'react-modal';
export default function Notifiche(props) {
    const [titolo, setTitolo] = useState('')
    const [testo, setTesto] = useState('')
    const [query, setQuery] = useState('')
    const [times, setTimes] = useState(0)
    const [limit, setLimit] = useState('')
    const [editionId, setEditionId] = useState([])
    const [editions, setEditions] = useState([])
    const [leagues, setLeagues] = useState([])
    const [leagueId, setLeagueId] = useState([])
    const [customNotification, setCustomNotification] = useState(false)
    const [notificationType, setNotificationType] = useState(0)
    const [openPopup, setOpenPopup] = useState(false)
    const [loading, setLoading] = useState(true)
    const [inOut, setInOut] = useState(true)
    const [inOutUsers, setInOutUsers] = useState(true)

    useEffect(() => {
        getEditions()
    }, [])
    const handleChange = (e, type) => {

        switch (type) {
            case 'titolo':
                setTitolo(e.target.value);
                break;

            case 'testo':
                setTesto(e.target.value);
                break;
            case 'editionId':
                setEditionId(e.target.value);
                break;
            case 'leagueId':
                setLeagueId(e.target.value);
                break;
            case 'query':
                setQuery(e.target.value);
                break;
            case 'times':
                setTimes(e.target.value);
                break;
            case 'limit':
                setLimit(e.target.value);
                break;
            default:
                break;

        }
    }
    const getEditions = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/allEditionsMinimal", requestOptions)
            .then(response => response.json())
            .then(result => {
                let tmp = []
                result.response.map((ed) => {
                    tmp.push({
                        label: ed.name,
                        value: ed.id
                    })
                })
                setEditions(tmp)
                setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });
        return res

    }
    const getLeagues = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/League/getAll", requestOptions)
            .then(response => response.json())
            .then(result => {
                let tmp = []
                result.response.map((ed) => {
                    tmp.push({
                        label: ed.name,
                        value: ed.idLeague
                    })
                })
                setLeagues(tmp)
                setLoading(false)
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
            });
        return res

    }
    const selectType = (e) => {

        if (e.target.value == 2 && leagues.length == 0)
            getLeagues()
        setNotificationType(e.target.value)
    }
    let customModalStylesBoard = {

        content: {
            background: '#1c3144',

            width: '60%',
            height: '70vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '10%',
            position: 'relative'
        },

    };
    const setCustomNotificationFunc = (value) => {
        setCustomNotification(value)
        setTitolo('')
        setTesto('')
        setTimes(0)
        setLimit('')
        setEditionId([])
        setLeagueId([])
    }
    const send = () => {
        setLoading(true)
        let path = ''
        let obj = {}
        if (customNotification) {
            path = '/survivor/notifications/custom'
            obj = {
                campaign: {
                    title: titolo,
                    message: testo,
                    query: query,
                    operations: true
                }
            }
        }
        else if (!customNotification && notificationType == 0) {
            path = '/survivor/notifications/Edition/notPlayed'
            obj = {
                campaign: {
                    times: times,
                    size: limit,
                    title: titolo,
                    message: testo,
                    edition: editionId.value,
                    operations: true
                }
            }
        }
        else if (!customNotification && notificationType == 1) {
            path = '/survivor/notifications/campaign'
            obj = {
                campaign: {
                    times: times,
                    size: limit,
                    title: titolo,
                    message: testo,
                    editionid: editionId.value,
                    operator: inOut ? '=' : '!=',
                    operations: true,
                    active: !inOutUsers ? 1 : null
                }
            }

        }
        else if (!customNotification && notificationType == 2) {
            path = '/survivor/notifications/league'
            obj = {
                campaign: {
                    times: times,
                    size: limit,
                    title: titolo,
                    message: testo,
                    league: leagueId.value,
                    operator: inOut ? '=' : '!=',
                    operations: true
                }
            }

        }


        let toReturn = {
            body: obj,
            path: path,
            httpMethod: 'POST',
            env: sessionStorage.getItem('env') === 'https://api.skillbol.com' ? 'PROD' : 'DEV',
            isBase64Encoded: false
        }

        fetch('https://nd3nnk6inlz75oovaaqdr2al5e0qqkcz.lambda-url.eu-west-2.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify(toReturn)
        }).then(response => response.json())
            .then(result => {
                console.log(result)
                setLoading(false)
                toast.success('Processo invio notifiche avviato!')


            }).catch((err) => {
                setLoading(false)
                toast.error(err.message)
            })



    }
    let query_value = 'SELECT distinct(token) FROM user_token where user_id IN (select user_id from life where edition_id = ${id} group by user_id HAVING count(user_id) = 2 order by user_id asc) LIMIT 3000 OFFSET 6000'
    return (
        loading == true ? <div className="loader_cont"><div className="loader"></div> </div> :
            <div>
                <div className='text-center-surv edition-table-cont' >
                    <div className={window.innerWidth > 1000 ? "w-50 " : 'w-100'}>
                        <div className=' admin_text_message' Style={'margin-top:20px'}>Predefinite/Custom</div>
                        <div className='text-center'>
                            <Switch onChange={(e) => setCustomNotificationFunc(!customNotification)} checked={customNotification} offColor={'#c20000'} checkedIcon={''} uncheckedIcon={''} />
                        </div>
                        {customNotification ?
                            <div className='admin_text' Style={'margin-top:20px; font-size:25px'}>Custom Notification</div>
                            : <div className='admin_text' Style={'margin-top:20px;font-size:25px'}> Notifiche Predefinite</div>}
                        <div className='admin_text' Style={'margin-top:20px'}>Titolo</div>
                        <input className='admin_text input_text' type='text' value={titolo} onChange={(e) => handleChange(e, 'titolo')} />
                        <div className='admin_text' Style={'margin-top:20px'}>Testo</div>
                        <input className='admin_text input_text' type='text' value={testo} onChange={(e) => handleChange(e, 'testo')} />


                        {customNotification ? <div>
                            <div className='admin_text' Style={'margin-top:20px; '}>Query</div>
                            <div className='admin_text flex' > {query_value}</div>
                            <div className='admin_text'>
                                <svg onClick={() => navigator.clipboard.writeText(query_value)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class=" pointer bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg></div>
                            <textarea rows={3} className='admin_text input_text' type='text' value={query} onChange={(e) => handleChange(e, 'query')} />
                        </div>
                            : <div>
                                <div className="flex admin_text">
                                    <div className="w-50 ">
                                        <div className='admin_text' Style={'margin-top:20px'}>Times</div>
                                        <input className='admin_text input_text' type='number' value={times} onChange={(e) => handleChange(e, 'times')} />
                                    </div>
                                    <div className="w-50">
                                        <div className='admin_text' Style={'margin-top:20px'}>Limit</div>
                                        <input className='admin_text input_text' type='number' value={limit} onChange={(e) => handleChange(e, 'limit')} />
                                    </div>
                                </div>

                                <div className='admin_text' Style={'margin-top:20px;'}> Tipo notifica</div>
                                <select Style={'margin-left:22px'} onChange={selectType}>
                                    <option value={0}>Edition - Not Played</option>
                                    <option value={1}>Edition</option>
                                    <option value={2}>League </option>
                                </select>
                            </div>}
                        {!customNotification && (notificationType == 1 || notificationType == 2) ? <div className='text-center ' Style={'margin-top:20px;'}><div className="admin_text" Style={'margin-left:0;width:100%'}>{inOut ? 'Iscritti' : 'Non Iscritti'}</div>
                            <Switch onChange={(e) => setInOut(e)} checked={inOut} offColor={'#c20000'} checkedIcon={''} uncheckedIcon={''} />
                        </div> : ''}
                        {!customNotification && (notificationType == 1 || notificationType == 2) && inOut ?
                            <div className='text-center ' Style={'margin-top:20px'}><div className="admin_text" Style={'margin-left:0;width:100%'}>{inOutUsers ? 'Tutti' : 'In vita'}</div>
                                <Switch onChange={(e) => setInOutUsers(e)} checked={inOutUsers} offColor={'#008800'} checkedIcon={''} uncheckedIcon={''} />
                            </div> : ''}
                        {!customNotification && (notificationType == 0 || notificationType == 1) ?
                            <div>
                                <div className='admin_text' Style={'margin-top:20px'}>Edition ID</div>

                                <Select
                                    id='select'
                                    className={'testing w-50'}
                                    options={editions && editions.length > 0 ? editions : []}
                                    value={editionId}
                                    onChange={setEditionId}
                                    labelledBy="Select"
                                    overrideStrings={{
                                        selectSomeItems: "Select .",

                                        selectAll: "Select All",
                                        search: "Search",
                                    }}
                                    isCreatable={false}
                                    hasSelectAll={false} />

                            </div>
                            : (!customNotification && notificationType == 2 ? <div>
                                <div className='admin_text' Style={'margin-top:20px'}>League ID</div>

                                <Select
                                    id='select'
                                    className={'testing w-50'}
                                    options={leagues && leagues.length > 0 ? leagues : []}
                                    value={leagueId}
                                    onChange={setLeagueId}
                                    labelledBy="Select"
                                    overrideStrings={{
                                        selectSomeItems: "Select .",

                                        selectAll: "Select All",
                                        search: "Search",
                                    }}
                                    isCreatable={false}
                                    hasSelectAll={false} />
                            </div> : '')}
                    </div>
                    <button className='button ' Style={'margin-top:50px'} onClick={() => setOpenPopup(true)}> Invia Notifica</button>
                </div>
                <Modal
                    isOpen={openPopup}
                    onAfterOpen={null}
                    onRequestClose={() => setOpenPopup(false)}
                    style={customModalStylesBoard}
                    className={'modal_base confirm_delete'}
                    appElement={document.getElementById('root') || undefined}

                >
                    <div className="modal_content_container">
                        <div className="modal_header flex">

                            <div className="modal_content_title">Conferma </div>


                            <div className="close_button flex" >


                                <svg onClick={() => setOpenPopup(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </div>

                        <div>
                            <div className='admin_text' Style={'margin-top:20px'}>Tipo Notifica: {customNotification ? 'Custom' : 'Predefinita'}</div>
                            {!customNotification ? <div className='admin_text' Style={'margin-top:20px'}>Modello selezionato: {
                                notificationType == 0 ? ' Edition - Not Played' : (notificationType == 1 ? 'Edition' : (notificationType == 2 ? 'League - All users' : ''))
                            }</div> : ''}
                            <div className='admin_text flex ' Style={'margin-top:20px'}>Titolo: {titolo}</div>
                            <div className='admin_text' Style={'margin-top:20px'}>Testo: {testo}</div>
                            {customNotification ? <div className='admin_text' Style={'margin-top:20px'}>Query: {query}</div> : ''}

                            {!customNotification && (notificationType == 0 || notificationType == 1) ?
                                <div className='admin_text' Style={'margin-top:20px'}>EditionId: {editionId.value} - {editionId.label}</div>
                                : (!customNotification && notificationType == 2 ?
                                    <div className='admin_text' Style={'margin-top:20px'}>LeagueId: {leagueId.value} - {leagueId.label}</div> : '')}

                            {!customNotification && (notificationType == 1 || notificationType == 2) ? <div className='admin_text' Style={'margin-top:20px'}>Utenti: {inOut ? 'Iscritti' : 'Non Iscritti'}</div> : ''}
                            {!customNotification && (notificationType == 1 || notificationType == 2) && inOut ? <div className='admin_text' Style={'margin-top:20px'}>Utenti: {inOutUsers ? 'Tutti' : 'In Vita'}</div> : ''}


                        </div>
                        <div className='admin_text'>
                            <button className='button ' Style={'margin-top:50px'} onClick={() => send()}> Invia Notifica</button>
                        </div>
                    </div>

                </Modal>
                <ToastContainer theme="colored" />
            </div >)
}