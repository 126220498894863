import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBhAa5SECrljh--tqVD9gX410t_JEZtSDg",
    authDomain: "survivor-extro.firebaseapp.com",
    projectId: "survivor-extro",
    storageBucket: "survivor-extro.appspot.com",
    messagingSenderId: "812994983724",
    appId: "1:812994983724:web:fc86b91c07bd14d52d1e89",
    measurementId: "G-4ZJNKJ3CFT",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Firebase storage reference
const storage = getStorage(app);
export default storage;