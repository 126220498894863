import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Configurations(props) {
    const [loading, setLoading] = useState(true)
    const [configurations, setConfigurations] = useState([])
    const [editing, setEditing] = useState(false)
    const [editChiave, setEditChiave] = useState('')
    const [editValore, setEditValore] = useState('')
    const [newChiave, setNewChiave] = useState('')
    const [newValore, setNewValore] = useState('')
    const [editIndex, setEditIndex] = useState(null)
    const [removeKey, setRemoveKey] = useState(null)
    const [open_modal, setOpenModal] = useState(false)
    const [confirm_delete_modal, setOpenConfirmModal] = useState(false)

    const getConfigurations = async () => {
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/configurations", requestOptions)
            .then(response => response.json())
            .then(result => {
                setConfigurations(result.response)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)
            });
        return res
    }
    const sendConfiguration = async (type) => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');

        let toReturn = {}
        if (type === 'edit')
            toReturn = {
                chiave: editChiave,
                valore: editValore
            }
        else {
            toReturn = {
                chiave: newChiave,
                valore: newValore
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(toReturn)
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/configurations", requestOptions)
            .then(response => response.json())
            .then(result => {
                toast.success(type === 'edit' ? 'Configuration aggiornata' : 'Configuration aggiunta')
                getConfigurations()
                closeModal()
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.message)
                console.log('error', error)
            });
        return res
    }

    const deleteConfiguration = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');

        let toReturn = {}
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(toReturn)
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/configurations/" + removeKey, requestOptions)
            .then(result => {
                console.log(result)
                if (result.status === 200) {
                    result.text().then(res => {
                        let message = JSON.parse(res)
                        console.log(message);
                        toast.success(message.message)
                        getConfigurations()
                        closeConfirmModal()
                    })
                } else {
                    setLoading(false)
                    result.text().then(err => {
                        let message = JSON.parse(err)
                        if (message.message) {
                            toast.error(message.message)
                        }
                        else {
                            console.log(message.error);
                            toast.error(message.error)
                            console.log('error', result.status)
                        }
                        closeConfirmModal()
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.message)
                console.log('error', error)
                closeConfirmModal()
            });
        return res
    }

    useEffect(() => {
        getConfigurations()

    }, [])
    const edit = (e, index) => {
        setEditing(true)
        setEditChiave(e.chiave)
        setEditValore(e.valore)
        setEditIndex(index)
    }

    const remove = (e) => {
        setRemoveKey(e.chiave)
        setOpenConfirmModal(true)
    }

    const handleChiave = (e, type) => {
        if (type === 'edit')
            setEditChiave(e.target.value)
        else
            setNewChiave(e.target.value)

    }
    const handleValore = (e, type) => {
        if (type === 'edit')
            setEditValore(e.target.value)
        else
            setNewValore(e.target.value)
    }
    const save = () => {
        sendConfiguration('edit')
        setEditing(false)
    }
    const cancel = () => {
        setEditing(false)
        setEditChiave('')
        setEditValore('')
    }
    const insert = () => {
        sendConfiguration('new')
    }
    const closeModal = () => {
        setNewChiave('')
        setNewValore('')
        setOpenModal(false)
    }
    const closeConfirmModal = () => {
        setRemoveKey('')
        setOpenConfirmModal(false)
    }
    let configurations_value = ''

    if (configurations && configurations.length > 0) {
        configurations_value = configurations.map((e, index) =>

            <tr Style={'text-transform: none'}>
                <td> {e.chiave}</td>
                <td Style={'white-space: pre-line;'}>{editing && editIndex == index ? <textarea Style={'width:80%'} value={editValore ? editValore : ''} onChange={(e) => handleValore(e, 'edit')} /> : e.valore.replaceAll(';', '\n')}</td>
                {editing && editIndex == index ?
                    <td className='flex'>
                        <button className='button ' onClick={() => save()}> Salva</button>
                        <button className='disabled_button ml-3' onClick={() => cancel()} > Annulla</button>
                    </td>
                    :

                    <td Style={'place-content: center;'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={window.innerWidth < 700 ? "13" : "25"} height={window.innerWidth < 700 ? "13" : "25"} fill="currentColor" class="bi bi-pencil-square pointer ml-5" viewBox="0 0 16 16" onClick={() => edit(e, index)}>
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" width={window.innerWidth < 700 ? "13" : "25"} height={window.innerWidth < 700 ? "13" : "25"} fill="currentColor" class="bi bi-trash3 pointer ml-10" viewBox="0 0 16 16" onClick={() => remove(e)}>
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                        </svg>
                    </td>

                }
            </tr>
        )
    }
    let customModalStylesTracking = {

        content: {
            background: '#1c3144',

            width: '40%',
            height: '50vh',
            marginRight: '-50%',
            padding: '10px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '6%'
        },

    };

    let customModalStylesConfirmation = {

        content: {
            background: '#1c3144',

            width: '23%',
            height: '25vh',
            marginRight: '-50%',
            padding: '15px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '10%',
            position: 'relative'
        },

    };
    return (
        <React.Fragment>
            <div className='text-center-surv edition-table-cont' >

                <table class='surv-table-configurations '>
                    <thead>
                        <th>Chiave</th>
                        <th>Valore</th>
                        <th>Azioni</th>

                    </thead>
                    <tbody Style={'margin-top:20px'}>
                        {!loading ? configurations_value : <div className="loader_cont"><div className="loader"></div></div>}
                    </tbody>
                </table>
                <button className='round_button' onClick={() => { setOpenModal(true); setEditing(false) }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-plus mt--15" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg></button>
            </div>
            <Modal
                isOpen={open_modal}
                onAfterOpen={null}
                onRequestClose={() => closeModal()}
                style={customModalStylesTracking}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}
            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Nuova Configurazione</div>


                        <div className="close_button flex" >

                            <svg onClick={() => closeModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                    <div className='modal_content'>

                        <div className='modal_p_title'>
                            Chiave
                        </div>
                        <input className='admin_text input_text' type='text' value={newChiave ? newChiave : ''} onChange={(e) => handleChiave(e, 'new')} />
                        <div className='modal_p_title'>
                            Valore
                        </div>

                        <input className='admin_text input_text' type='text' value={newValore ? newValore : ''} onChange={(e) => handleValore(e, 'new')} />

                    </div>

                    <div className='save_button_cont' onClick={() => insert()}>
                        <button className='save_button'>Salva</button>
                    </div>
                </div >

            </Modal >

            <Modal
                isOpen={confirm_delete_modal}
                onAfterOpen={null}
                onRequestClose={() => closeConfirmModal()}
                style={customModalStylesConfirmation}
                className={'modal_base confirm_delete'}
                appElement={document.getElementById('root') || undefined}
            >
                <div className="modal_content_container">
                    <div className="modal_header flex">

                        <div className="modal_content_title">Conferma eliminazione</div>


                        <div className="close_button flex" >

                            <svg onClick={() => closeConfirmModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>

                    <div className='save_button_cont'>
                        <button className='save_button' Style={'margin-right:30px'} onClick={() => deleteConfiguration(false)}>Elimina</button>
                        <button className='save_button' onClick={() => closeConfirmModal(false)}>Annulla</button>
                    </div>
                </div >

            </Modal >

            <ToastContainer theme="colored" />
        </React.Fragment>)
}