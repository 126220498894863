import React, { useEffect } from "react";
import Modal from 'react-modal';
import Switch from "react-switch";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Leghe(props) {
    const [loading, setLoading] = React.useState(false)
    const [addLeaguePopup, setAddLeaguePopup] = React.useState(false)
    const [addCleanTeamsPopup, setOpenConfirmCleanTeamsModal] = React.useState(false)
    const [addDeleteLeaguePopup, setOpenConfirmDeleteLeagueModal] = React.useState(false)
    const [addConfirmAddMatchdayPopup, setOpenConfirmAddMatchdayModal] = React.useState(false)
    const [addCleanLeaguePopup, setOpenConfirmCleanLeagueModal] = React.useState(false)
    const [leagueToClean, setCleanLeague] = React.useState(false)
    const [addTeamPopup, setAddTeamPopup] = React.useState(false)
    const [freePlay, setFreePlay] = React.useState(false)
    const [visible, setVisible] = React.useState(false)
    const [leagues, setLeagues] = React.useState([])
    const [name, setName] = React.useState('')
    const [colors, setColors] = React.useState([])
    const [firstColor, setFirstColor] = React.useState('')
    const [secondColor, setSecondColor] = React.useState('')
    const [editFirstColor, setEditFirstColor] = React.useState('')
    const [editSecondColor, setEditSecondColor] = React.useState('')
    const [team_name, setTeamName] = React.useState('')
    const [teamsNumber, setTeamsNumber] = React.useState('')
    const [leagueLink, setLeagueLink] = React.useState(false)
    const [teamsView, setTeamsView] = React.useState(false)
    const [editView, setEditView] = React.useState(false)
    const [matchdayView, setMatchdayView] = React.useState(false)
    const [teamToEdit, setTeamToEdit] = React.useState('')
    const [selectedLeague, setSelectedLeague] = React.useState(false)
    const [selectedLeagueTeams, setSelectedLeagueTeams] = React.useState([])
    const [disabledTeams, setDisabledTeams] = React.useState([])
    const [toDelete, setToDelete] = React.useState('')
    const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)
    const [toStop, setToStop] = React.useState('')
    const [toDisabledAdd, setToDisabledAdd] = React.useState('')
    const [stopConfirmation, setStopConfirmation] = React.useState(false)
    const [disabledAddConfirmation, setDisabledAddConfirmation] = React.useState(false)
    const [toPlay, setToPlay] = React.useState('')
    const [playConfirmation, setPlayConfirmation] = React.useState(false)
    const [league_id, setLeagueId] = React.useState('')
    const [sport, setSport] = React.useState('Soccer')
    const [homeTeam, setHomeTeam] = React.useState('')
    const [awayTeam, setAwayTeam] = React.useState('')
    const [matchesList, setMatchesList] = React.useState([])
    const [giornataMatches, setGiornataMatches] = React.useState(null)
    const [competition, setCompetition] = React.useState('')
    const [competitionValue, setCompetitionValue] = React.useState('')
    const [tosend, setToSend] = React.useState([])
    const [request, setRequest] = React.useState(null)

    useEffect(() => {
        setLoading(true)
        getColors()
        getLeghe().then(() => {
            setLoading(false)
        })
    }, [])

    const getLeghe = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/getAll', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })
            .then(response => response.json())
            .then(result => {
                setLeagueId(result.response[0].id)
                setLeagues(result.response)
            }).catch((err) => {
                console.log(err)
            })
    }
    const getColors = async () => {
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/getAllColors', {
            method: 'GET',
            headers: {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
        })
            .then(response => response.json())
            .then(result => {
                setColors(result.response.sort())
                setFirstColor(result.response.sort()[0] === 'azure' ? 'aqua' : result.response.sort()[0])
                setSecondColor(result.response.sort()[0] === 'azure' ? 'aqua' : result.response.sort()[0])
            }).catch((err) => {
                console.log(err)
            })
    }
    const handleChange = (e, type) => {
        let team;
        switch (type) {
            case 'free_play':
                setFreePlay(e)
                break;
            case 'visible':
                setVisible(e)
                break;
            case 'name':
                setName(e.target.value)
                break;
            case 'teams':
                setTeamsNumber(e.target.value)
                break;
            case 'sport':
                setSport(e.target.value)
                break;
            case 'team_name':
                setTeamName(e.target.value)
                break;
            case 'edit_first_color':
                setEditFirstColor(e.target.value.replace('azure,aqua'))
                break;
            case 'edit_second_color':
                setEditSecondColor(e.target.value.replace('azure,aqua'))
                break;
            case 'first_color':
                setFirstColor(e.target.value === 'azure' ? 'aqua' : e.target.value)
                break;
            case 'second_color':
                setSecondColor(e.target.value === 'azure' ? 'aqua' : e.target.value)
                break;
            case 'league_link':
                setLeagueLink(e)
                break;
            case 'league_id':
                setLeagueId(e.target.value)
                break;
            case 'home_team':
                setHomeTeam(e.target.value)
                break;
            case 'away_team':
                setAwayTeam(e.target.value)
                break;
            case 'giornata_matches':
                setGiornataMatches(e.target.value)
                break;
            case 'competition':
                setCompetition(e.target.value)
                break;
            case 'competition_value':
                setCompetitionValue(e.target.value)
                break;
            default:
                break;
        }

    }
    const updateTeam = () => {
        setLoading(true)
        let teamToCreate = {
            name: teamToEdit.name,
            firstColor: editFirstColor.replace('aqua', 'azure'),
            secondColor: editSecondColor.replace('aqua', 'azure')
        }
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeader,
            redirect: 'follow',
            body: JSON.stringify([teamToCreate])
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/add', requestOptions)
            .then(response => response.json())
            .then(result => {
                getLeghe().then(() => {
                    setEditView(false)
                    getLeagueTeams(selectedLeague.idLeague)
                    setTeamsView(true)
                    setLoading(false)
                    toast.success('Squadra aggiornata correttamente')
                })
            }).catch((err) => {
                console.log(err)
                toast.error("Qualcosa è andato storto")
                setLoading(false)
            }).finally(() => {
                setTeamToEdit('')
                setLeagueLink(false)
                setLeagueId(leagues[0])
                setFirstColor(colors[0] !== 'azure' ? colors[0] : 'aqua')
                setTeamsNumber(colors[0] !== 'azure' ? colors[0] : 'aqua')
                setLoading(false)
            })
    }
    const createTeam = () => {
        if (team_name === '') {
            toast.warn('Errore: Inserisci il nome del Team')
        } else {


            let teamToCreate = {
                name: team_name,
                firstColor: firstColor === 'aqua' ? 'azure' : firstColor,
                secondColor: secondColor === 'aqua' ? 'azure' : secondColor
            }

            let leagueParam = ''
            if (leagueLink)
                leagueParam = '?idLeague=' + league_id
            else if (!leagueLink && teamsView)
                leagueParam = '?idLeague=' + selectedLeague.idLeague

            let myHeader = {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeader,
                redirect: 'follow',
                body: JSON.stringify([teamToCreate])
            }
            fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/add' + leagueParam.toString(), requestOptions)
                .then(response => response.json())
                .then(result => {
                    getLeghe().then(() => {
                        setAddTeamPopup(false)
                        getLeagueTeams(selectedLeague.idLeague)
                        setLoading(false)
                        toast.success('Squadra creata correttamente')
                        getDisabledTeams(selectedLeague.idLeague).then(() => {
                            setDisabledTeams(true)

                        }).catch(() => {
                            toast.error('Errore: qualcosa è andato storto')
                            setLoading(false)
                        })
                    })
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                }).finally(() => {
                    setTeamName('')
                    setLeagueLink(false)
                    setLeagueId(leagues[0])
                    setFirstColor(colors[0] !== 'azure' ? colors[0] : 'aqua')
                    setTeamsNumber(colors[0] !== 'azure' ? colors[0] : 'aqua')
                })
        }
    }

    const setOpenConfirmCleanLeague = (league) => {
        setOpenConfirmCleanLeagueModal(true)
        setCleanLeague(league.idLeague)
    }
    const setOpenConfirmDeleteLeague = (league) => {
        setOpenConfirmDeleteLeagueModal(true)
        setCleanLeague(league.idLeague)
    }
    const setOpenConfirmAddMatchday = (league) => {
        setOpenConfirmAddMatchdayModal(true)
    }

    const cleanLeague = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/League/" + leagueToClean + "/clean", requestOptions)
            .then(result => {
                console.log(result)
                if (result.status === 200) {
                    result.text().then(res => {
                        let message = JSON.parse(res)
                        console.log(message.message);
                        getLeghe().then(() => {
                            setLoading(false)
                            setTimeout(() => {

                                toast.success(message.message)
                            }, 300);
                        }).catch(() => {
                            toast.error('Errore: qualcosa è andato storto')
                            setLoading(false)
                        })
                        closeConfirmCleanLeagueModal()
                    })
                } else {
                    setLoading(false)
                    result.text().then(err => {
                        console.log(err)
                        let message = JSON.parse(err)
                        if (message.message) {
                            toast.error(message.message)
                        }
                        else {
                            console.log(message.error);
                            toast.error(message.error)
                            console.log('error', result.status)
                        }
                        closeConfirmCleanLeagueModal()
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.message)
                console.log('error', error)
                closeConfirmCleanLeagueModal()
            });
        return res
    }
    const deleteLeague = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/League/" + leagueToClean, requestOptions)
            .then(result => {
                console.log(result)
                if (result.status === 200) {
                    result.text().then(res => {
                        let message = JSON.parse(res)
                        console.log(message);
                        setLoading(false)
                        setTimeout(() => {
                            toast.success(message.message)
                        }, 300);
                        getLeghe().then(() => {
                        }).catch(() => {
                            toast.error('Errore: qualcosa è andato storto')
                            setLoading(false)
                        })

                        closeConfirmDeleteLeagueModal()
                    })
                } else {
                    setLoading(false)
                    result.text().then(err => {
                        let message = JSON.parse(err)
                        if (message.message) {
                            toast.error(message.message)
                        }
                        else {
                            console.log(message.error);
                            toast.error(message.error)
                            console.log('error', result.status)
                        }
                        closeConfirmDeleteLeagueModal()
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.message)
                console.log('error', error)
                closeConfirmDeleteLeagueModal()
            });
        return res
    }


    const createLeague = () => {
        if (name === '') {
            toast.warn('Errore: Inserisci il nome della lega')
        }
        else if (competitionValue === '') {
            toast.warn('Errore: Inserisci la competition')
        }
        else {
            setAddLeaguePopup(false)
            setLoading(true)
            let leagueToCreate = {
                name: name,
                sport: sport,
                numberOfTeams: 0,
                freePlay: freePlay,
                isVisible: visible
            }
            let myHeader = {
                'jwt': sessionStorage.getItem('val'),
                'Content-Type': 'application/json',
                'appVersion': '1000'
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeader,
                redirect: 'follow',
                body: JSON.stringify(leagueToCreate)
            }
            fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/add?competitions=' + competitionValue, requestOptions)
                .then(response => response.json())
                .then(result => {
                    getLeghe().then(() => {
                        setLoading(false)
                    })
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                }).finally(() => {
                    setName('')
                    setSport('Soccer')
                    setTeamsNumber('')
                    setCompetitionValue('')
                    setFreePlay(false)
                })
        }
    }
    const getLeagueTeams = async (id) => {
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeader,
            redirect: 'follow'
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/getAllByLeague/' + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                setSelectedLeagueTeams(result.response)
                return result.response
            })
    }
    const getDisabledTeams = async (id) => {
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeader,
            redirect: 'follow'
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/getAllByLeagueNot/' + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDisabledTeams(result.response).then(() => {
                })
            })
    }
    const removeAllTeams = async () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("jwt", sessionStorage.getItem('val'));
        myHeaders.append("appVersion", '1000');
        myHeaders.append("Content-Type", 'application/json');

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
        }
        const res = await fetch(sessionStorage.getItem('env') + "/survivor/api/v1/Admin/League/" + selectedLeague.idLeague + "/removeAllTeams", requestOptions)
            .then(result => {
                console.log(result)
                if (result.status === 200) {
                    result.text().then(res => {
                        let message = JSON.parse(res)
                        console.log(message);
                        toast.success(message.message)
                        getLeagueTeams(selectedLeague.idLeague).then(() => {
                            setTeamsView(true)
                        })
                        getDisabledTeams(selectedLeague.idLeague).then(() => {
                            setDisabledTeams(true)

                        }).catch(() => {
                            toast.error('Errore: qualcosa è andato storto')
                            setLoading(false)
                        })
                        setTimeout(() => {

                            setLoading(false)
                        }, 2000);
                        closeConfirmModal()
                    })
                } else {
                    setLoading(false)
                    result.text().then(err => {
                        let message = JSON.parse(err)
                        console.log(message.error);
                        toast.error(message.error)
                        console.log('error', result.status)
                        closeConfirmModal()
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                toast.error(error.message)
                console.log('error', error)
                closeConfirmModal()
            });
        return res
    }
    const selectLeagueTeams = (league) => {
        setLoading(true)
        setSelectedLeague(league)
        getLeagueTeams(league.idLeague).then(() => {
            setTeamsView(true)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
        getDisabledTeams(league.idLeague).then(() => {
            setDisabledTeams(true)
        }).catch(() => {
        })

    }
    const backLeague = () => {
        setTeamsView(false)
        setEditView(false)
        setMatchdayView(false)
        setSelectedLeague('')
    }
    const backTeam = () => {
        setTeamsView(true)
        setEditView(false)
        setTeamToEdit('')
    }
    const disconnect = (s) => {
        setToDelete(s)
        setDeleteConfirmation(true)
    }
    const deleteTeam = () => {
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'PUT',
            headers: myHeader,
            redirect: 'follow'
        }
        let responseStatus = ''
        let responseMessage = ''
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/' + toDelete.name + '/removeFromLeague/' + selectedLeague.idLeague, requestOptions)
            .then(response => {
                responseStatus = response.status
                return response.json()
            })
            .then(result => {
                responseMessage = result.message
                if (responseStatus == 200) {
                    toast.success('Squadra rimossa dalla lega')
                    setDeleteConfirmation(false)
                    getLeagueTeams(selectedLeague.idLeague).then(() => {
                        setTeamsView(true)
                        setLoading(false)
                    })
                    getDisabledTeams(selectedLeague.idLeague).then(() => {
                        setDisabledTeams(true)

                    }).catch(() => {
                        toast.error('Errore: qualcosa è andato storto')
                        setLoading(false)
                    })
                }
                else {
                    toast.error(responseMessage)
                }
            }).catch(() => {
                toast.error('Errore: qualcosa è andato storto')
            })
    }
    const goToEdit = (s) => {
        setTeamToEdit(s)
        setEditFirstColor(s.firstColor.replace('azure', 'aqua'))
        setEditSecondColor(s.secondColor.replace('azure', 'aqua'))
        setTeamsView(false)
        setEditView(true)
    }
    const goToPlay = (s) => {
        setToPlay(s)
        setPlayConfirmation(true)
    }
    const goToStop = (s) => {
        setToStop(s)
        setStopConfirmation(true)
    }
    const stopLega = () => {
        setLoading(true)
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'PUT',
            headers: myHeader,
            redirect: 'follow'
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/' + toStop.idLeague + '/post', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.server == 200) {
                    setTimeout(() => {
                        toast.success(result.message)
                    }, 500);
                    setStopConfirmation(false)
                    getLeghe().then(() => {
                        setLoading(false)
                    })
                }
                else {
                    setLoading(false)
                    setTimeout(() => {

                        toast.error(result.message)
                    }, 500);
                    setStopConfirmation(false)
                }
            }).catch(() => {
                toast.error('Errore: qualcosa è andato storto')
                setLoading(false)
            })
    }
    const playLega = () => {
        setLoading(true)
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'PUT',
            headers: myHeader,
            redirect: 'follow'
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/League/' + toPlay.idLeague + '/ongoing', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.server == 200) {
                    toast.success('Lega avviata correttamente')
                    setPlayConfirmation(false)
                    getLeghe().then(() => {
                        setLoading(false)
                    })
                }
                else {
                    toast.error('Errore: ' + result.message)
                    setLoading(false)
                    setPlayConfirmation(false)
                }
            }).catch(() => {
                toast.error('Errore: qualcosa è andato storto')
                setLoading(false)
                setPlayConfirmation(false)
            })
    }
    const addDisabledTeam = (s) => {
        setToDisabledAdd(s)
        setDisabledAddConfirmation(true)
    }
    const addDisabledTeamConfirm = (s) => {
        setLoading(true)
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeader,
            redirect: 'follow',
            body: JSON.stringify([toDisabledAdd])
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Teams/toLeague/' + selectedLeague.idLeague, requestOptions)
            .then(response => response.json())
            .then(result => {
                setDisabledAddConfirmation(false)
                toast.success('Team aggiunto alla lega')

                getLeagueTeams(selectedLeague.idLeague).then(() => {
                    setTeamsView(true)
                    setLoading(false)
                }).catch(() => {
                    toast.error('Errore: qualcosa è andato storto')
                    setLoading(false)
                })

                getDisabledTeams(selectedLeague.idLeague).then(() => {
                    setDisabledTeams(true)

                }).catch(() => {
                    toast.error('Errore: qualcosa è andato storto')
                    setLoading(false)
                })
            })
    }
    const addMatches = (league) => {
        setSelectedLeague(league)
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'GET',
            headers: myHeader,
            redirect: 'follow'
        }
        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Team/getAllByLeague/' + league.idLeague, requestOptions)
            .then(response => response.json())
            .then(result => {
                setSelectedLeagueTeams(result.response)

                if (result.response && result.response.length > 0) {
                    setHomeTeam(result.response[0].name)
                    setAwayTeam(result.response[0].name)
                }
                setMatchdayView(true)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

    }
    const addTmpMatches = () => {
        setLoading(true)
        let tmp = matchesList
        tmp.push({
            homeTeam: homeTeam,
            awayTeam: awayTeam
        })


        setMatchesList(tmp)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }
    const removeTmpMatch = (m, i) => {
        setLoading(true)
        let tmp = matchesList
        tmp.splice(i, 1)
        setMatchesList(tmp)
        setHomeTeam(selectedLeagueTeams[0].name)
        setAwayTeam(selectedLeagueTeams[0].name)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }
    const createMatches = () => {

        if (giornataMatches && giornataMatches > 0) {
            let error = false
            if (tosend && tosend.length > 0)
                tosend.map((t) => {
                    if (t.giornata == giornataMatches) {
                        toast.error('La giornata è già stata inserita!')
                        error = true
                    }
                })
            if (!error) {
                setLoading(true)
                let obj = {
                    league: selectedLeague.name,
                    giornata: giornataMatches,
                    competitions: competition === '' ? null : competition,
                    partite: matchesList
                }
                let tmp = tosend
                tmp.push(obj)
                setToSend(tmp)
                setTimeout(() => {
                    setMatchesList([])
                    setGiornataMatches('')
                    setHomeTeam(selectedLeagueTeams[0].name)
                    setAwayTeam(selectedLeagueTeams[0].name)
                    setLoading(false)
                }, 500);
            }
        } else {
            toast.warn('Aggiungi il numero della giornata')
        }
    }
    const removeGiornata = (g, i) => {
        setLoading(true)
        let tmp = tosend
        tmp.splice(i, 1)
        setToSend(tmp)
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }
    const closeConfirmModal = () => {
        setOpenConfirmCleanTeamsModal(false)
    }
    const closeConfirmCleanLeagueModal = () => {
        setOpenConfirmCleanLeagueModal(false)
        setCleanLeague('')
    }
    const closeConfirmDeleteLeagueModal = () => {
        setOpenConfirmDeleteLeagueModal(false)
        setCleanLeague('')
    }
    const closeConfirmAddMatchdayModal = () => {
        setOpenConfirmAddMatchdayModal(false)
    }
    const sendData = async (confirm, overwrite) => {
        let responseStatus = ''
        setLoading(true)
        let myHeader = {
            'jwt': sessionStorage.getItem('val'),
            'Content-Type': 'application/json',
            'appVersion': '1000'
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeader,
            redirect: 'follow',
            body: JSON.stringify(tosend)
        }
        if (!confirm) {
            setRequest(requestOptions)
        }
        else {
            requestOptions = request
            requestOptions.headers['confirm'] = true
            if (overwrite) {
                requestOptions.headers['overwrite'] = true
            }
        }

        fetch(sessionStorage.getItem('env') + '/survivor/api/v1/Admin/Game/loadFromJson', requestOptions)
            .then(response => {
                responseStatus = response.status
                return response.json()
            })
            .then(result => {
                if (result.status == 428) {
                    setOpenConfirmAddMatchdayModal(true)
                }
                else if (responseStatus == 200) {
                    setRequest(null)
                    setOpenConfirmAddMatchdayModal(false)
                    toast.success(result.message)
                }
                else {
                    toast.error(result.message)
                }
            }).catch((err) => {
                console.log(err)
                if (err.message)
                    toast.error(err.message)
            }).finally(() => {

                setGiornataMatches('')
                setCompetition('')
                setMatchesList([])
                setToSend([])
                setMatchdayView(false)
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
            })
    }
    let teams = ''
    if (selectedLeagueTeams && selectedLeagueTeams.length > 0)
        teams = selectedLeagueTeams.map((s) =>
            <div className="league_card">
                <div className="league_card_title">{s.name}</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil edit_team_ico" viewBox="0 0 16 16" onClick={() => goToEdit(s)}>
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-trash delete_team" viewBox="0 0 16 16" onClick={() => disconnect(s)}>
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                </svg>
                <div className="mt-3">
                    <div className="flex admin_text" >
                        <div className="tshirt_template_1"></div>
                        <div Style={'margin-left:4px; width: 5px; z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>


                    </div>
                </div>
            </div >)
    let disabled_teams = ''
    if (disabledTeams && disabledTeams.length > 0)
        disabled_teams = disabledTeams.map((s) =>
            <div className="league_card_disabled">
                <div className="league_card_title_disabled">{s.name}</div>

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" fill="currentColor" class="bi bi-plus-lg delete_team" viewBox="0 0 16 16" onClick={() => addDisabledTeam(s)}>
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
                <div className="mt-3">
                    <div className="flex admin_text" >
                        <div className="tshirt_template_2"></div>
                        <div Style={'opacity: 0.5;margin-left:4px; width: 5px; z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;z-index:1;  min-height:57px;  background:' + s.firstColor.replace('azure', 'aqua')}></div>
                        <div Style={'opacity: 0.5;width: 5px;  min-height:57px;  background:' + s.secondColor.replace('azure', 'aqua')}></div>


                    </div>
                </div>
            </div >)
    let cards = ''
    if (leagues.length > 0)
        cards = leagues.map((l) => <div className="league_card">

            <div className="league_card_title">{l.name}</div>
            <div className="trasher" onClick={() => setOpenConfirmDeleteLeague(l)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-trash delete_team" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                </svg>
            </div>
            <div className="eraser" onClick={() => setOpenConfirmCleanLeague(l)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-eraser pointer mt--15" viewBox="0 0 16 16">
                    <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
                </svg>
            </div>
            <div className={l.status === 'PRE' ? 'orange_bubble' : (l.status === 'ONGOING' ? 'green_bubble' : 'red_bubble')}></div>
            <div className="flex item_center pl-5" Style={'margin-top:10px'}>
                <div className={l.sport ? l.sport + "_ico team_ico" : 'default_ico team_ico'}></div>
                <div className="card_label" >{l.sport} </div>
            </div>

            <div className="flex item_center pl-5 pointer number_of_team underline" Style={'margin-top:10px;'} onClick={() => selectLeagueTeams(l)}>
                <div className={'default_ico team_ico'}></div>
                <div className="card_label" >{l.numberOfTeams} Squadre </div>
            </div>
            {!l.currentMatchday.Giornata ? '' :
                <div className="flex item_center pl-5" Style={'margin-top:5px'}>
                    <div className={'matchday_ico team_ico'}></div>
                    <div className="card_label" > Giornata: {l.currentMatchday.Giornata} - {l.currentMatchday.Status} </div>
                </div>}
            <div className="flex" Style={'margin-top:5px'}>


                {!l.currentMatchday.Deadline ?

                    <div className="flex item_center pl-5 pointer number_of_team " >
                        <div className="card_label" >No matchday </div>
                    </div>
                    :
                    <div className="flex item_center pl-5" Style={'margin-top:5px'}>
                        <div className={'deadline_ico team_ico'}></div>
                        <div>
                            <div className="card_label" > Deadline Matchday</div>
                            <div className="card_label_xs"> {new Date(l.currentMatchday.Deadline).toLocaleString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "2-digit"
                            })}</div>
                        </div>
                    </div>}

                <div className="add_bubble" onClick={() => addMatches(l)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                    </svg></div>
            </div>

            <div className="flex item_center pl-5" Style={'margin-top:5px'}>
                <div className={!l.isVisible ? 'not_visible_ico team_ico' : 'visible_ico team_ico'}></div>
                <div className="card_label" > {!l.isVisible ? 'Non visibile' : 'Visibile'} </div>
            </div>
            <div className={l.status === 'PRE' ? 'play_bubble' : (l.status === 'ONGOING' ? 'stop_bubble' : '')}>
                <div className="bubble_content" Style={l.status === 'ONGOING' ? 'left:7px' : ''}>
                    {l.status === 'PRE' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16" onClick={() => goToPlay(l)}>
                        <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                    </svg> : (l.status === 'ONGOING' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-stop-fill" viewBox="0 0 16 16" onClick={() => goToStop(l)}>
                        <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                    </svg> : '')
                    }
                </div>
            </div>
        </div>)
    let customModalStylesConfirmation = {
        content: {
            background: '#1c3144',
            width: '30%',
            height: '80vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '5%',
            position: 'relative'
        },
    };

    let customConfirmation = {
        content: {
            background: '#1c3144',
            width: '40%',
            height: '35vh',
            marginRight: '-50%',
            padding: '30px',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "border-radius": "30px",
            overflow: 'hidden',
            margin: 'auto',
            marginTop: '15%',
            position: 'relative'
        },
    };
    let colorsOptionFirstColor = ''
    if (colors && colors.length > 0)
        colorsOptionFirstColor = colors.map((c) => <option value={c} >{c}</option>)
    let leagueOptions = ''
    if (leagues && leagues.length > 0)
        leagueOptions = leagues.map((l) => <option value={l.idLeague}>{l.name}</option>)
    let matchesForLeague = ''
    if (selectedLeagueTeams)
        matchesForLeague = selectedLeagueTeams.map((t) => <option>{t.name}</option>)
    let addedMatches = ''
    if (matchesList)
        addedMatches = matchesList.map((m, i) => <div > {m.homeTeam}-{m.awayTeam}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle ml-10 pointer" viewBox="0 0 16 16" onClick={() => removeTmpMatch(m, i)}>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg></div>)

    let giornate = ''
    if (tosend && tosend.length > 0)
        giornate = tosend.map((t, ind) => {
            return <div className="grid mt-3">
                <div> Giornata {t.giornata}  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle ml-10 pointer" viewBox="0 0 16 16" onClick={() => removeGiornata(t, ind)}>
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg></div>
                {t.competitions ? <div> Competizioni - {t.competitions}</div> : ''}
                {
                    t.partite.map((p) =>

                        <div>{p.homeTeam} - {p.awayTeam}</div>
                    )
                }

            </div >
        })
    return (loading ?
        <div className="loader_cont"><div className="loader"></div> <ToastContainer theme="colored" /></div> :
        matchdayView ?
            <div>
                <div className="surv-text-white back_to_league ">
                    <div className="flex ">
                        <div className="pointer underline" onClick={() => backLeague(false)}>
                            Leghe
                        </div>
                        &nbsp;  {'>>'}  &nbsp;  Aggiungi Matches
                    </div>

                    &nbsp;
                </div>
                <div className="mt-3">
                    <div className='w-100 text text-center-surv'>
                        Lega {selectedLeague.name}
                    </div>
                </div>
                <div className="surv-text-white back_to_league place_center mt-10">
                    <div className="surv-text-white "> N° Giornata</div>
                    <input type="number" className="ml-10 w-15" onChange={(e) => handleChange(e, 'giornata_matches')} value={giornataMatches}></input>
                </div>
                <div className="surv-text-white back_to_league place_center mt-10">
                    <div className="surv-text-white "> Competizioni</div>
                    <input type="text" className="ml-10 w-15" onChange={(e) => handleChange(e, 'competition')} value={competition}></input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-info-circle surv-svg" viewBox="0 0 16 16" >
                        <title>0 - Serie A {'\n'}1 - Serie B {'\n'}2 - Champions League{'\n'}3 - Lega PRO {'\n'}20 - EU League{'\n'}21 - La Liga{'\n'}22 - Bundesliga{'\n'}23 - Ligue One{'\n'}24 - Premier League {'\n'}</title>   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                </div>
                <div className="surv-text-white" Style={'margin-left:50px'}> N.B. separatore ';'</div>
                <div className="flex" Style={'align-items: self-end;'}>
                    <div className="w-50">
                        <div className="surv-text-white mb-3">Home team</div>
                        <select value={homeTeam.name} onChange={(e) => handleChange(e, 'home_team')}>
                            {matchesForLeague}
                        </select>
                    </div>
                    <button className='button' Style={'height:25px; font-size:14px'} onClick={() => addTmpMatches()}>Aggiungi</button>
                    <div className="w-50">
                        <div className="surv-text-white mb-3">Away team</div>
                        <select value={homeTeam.name} onChange={(e) => handleChange(e, 'away_team')}>
                            {matchesForLeague}
                        </select>

                    </div>
                </div>
                <div className="mt-3">
                    <div className='w-100 text text-center-surv '>
                        {matchesList && matchesList.length == 0 ? 'Aggiungi i matches alla lista' : addedMatches}
                    </div>
                </div>
                {matchesList && matchesList.length > 0 ? <button className='button' Style={'height:25px; font-size:14px'} onClick={() => createMatches()}>Salva Giornata</button> : ''}

                {tosend.length > 0 ?
                    <>
                        <div className="surv-text-white   mt-50" Style={'text-align:left; margin-left:50px'}>  {giornate}</div>
                    </> : ''}
                {tosend && tosend.length > 0 ? <button className='button' Style={'height:25px; font-size:14px'} onClick={() => sendData(false)}>Invia Giornate</button> : ''}

            </div >
            : <React.Fragment>
                {
                    editView ?
                        <div >

                            <div className="surv-text-white back_to_league ">
                                <div className="flex ">
                                    <div className="pointer underline" onClick={() => backLeague(false)}>
                                        Leghe
                                    </div>
                                    &nbsp;  {'>>'}  &nbsp;  <div className="pointer underline" onClick={() => backTeam(false)}>Squadre {selectedLeague.name}</div> {'>>'}   &nbsp;Modifica Squadra
                                </div>

                                &nbsp;
                            </div>
                            <div Style={'width:90%;    text-align: -webkit-left; margin-top:5%; margin-left:3%'}>
                                < div className="mt-3">
                                    <div className='select_text_edit' Style={'font-size:18px;'}>
                                        Name:
                                    </div>
                                    <div className='select_text_edit' >

                                        {teamToEdit.name}
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className='select_text_edit'>
                                        Primo Colore
                                    </div>
                                    <select className='select_text ' onChange={(e) => handleChange(e, 'edit_first_color')} value={editFirstColor}>

                                        {colorsOptionFirstColor}
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <div className='select_text_edit'>
                                        Secondo Colore
                                    </div>
                                    <select className='select_text ' onChange={(e) => handleChange(e, 'edit_second_color')} value={editSecondColor}>
                                        {colorsOptionFirstColor}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <div className="flex admin_text " >
                                        <div className="tshirt_template"></div>
                                        <div Style={'margin-left:4px; width: 5px; z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;z-index:1;  min-height:58px;  background:' + editFirstColor.replace('azure', 'aqua')}></div>
                                        <div Style={'width: 5px;  min-height:58px;  background:' + editSecondColor.replace('azure', 'aqua')}></div>


                                    </div>
                                </div>
                                <div className="mt-3">
                                    <button className='save_button' Style={'margin-left:3%; margin-top:4%'} onClick={() => updateTeam()}>Modifica</button>
                                </div>
                            </div>

                        </div> : ''
                }


                :

                <React.Fragment>
                    {teamsView ? <div className="surv-text-white back_to_league ">
                        <div className="flex">
                            <div className="pointer underline" onClick={() => backLeague(false)}>
                                Leghe
                            </div>
                            &nbsp;  {'>>'}  &nbsp; Squadre {selectedLeague.name}
                        </div>
                    </div> : (matchdayView ? 'ADDMacthes' : '')}
                    <div className="cards_container">
                        {teamsView ?
                            teams : (!editView ? cards : '')}
                        {teamsView ? disabled_teams : ''}
                        {teamsView ? <button className='round_button' onClick={() => setOpenConfirmCleanTeamsModal(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-eraser mt--15" viewBox="0 0 16 16">
                                <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z" />
                            </svg>

                        </button> : <button className='round_button' onClick={() => setAddLeaguePopup(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="white" class="bi bi-plus mt--15" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>

                        </button>}
                        <button className='add_team_button' onClick={() => setAddTeamPopup(true)}>

                        </button>

                        <Modal
                            isOpen={addLeaguePopup}
                            onAfterOpen={null}
                            onRequestClose={() => setAddLeaguePopup(false)}
                            style={customModalStylesConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Creazione nuova Lega </div>


                                    <div className="close_button flex" >


                                        <svg onClick={() => setAddLeaguePopup(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>


                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Nome Lega
                                            </div>
                                            <input className='select_text input_text' type='text' value={name} onChange={(e) => handleChange(e, 'name')} />
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Sport
                                            </div>
                                            <select className='select_text ' onChange={(e) => handleChange(e, 'sport')}>
                                                <option value="Soccer">Soccer</option>
                                                <option value="Soccer">Tennis</option>
                                            </select>
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Competitions
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-info-circle surv-svg" viewBox="0 0 16 16" >
                                                    <title>2 - Champions League{'\n'}943 - EU League{'\n'}39 - Premier League {'\n'}61 - Ligue One{'\n'}78 - Bundesliga{'\n'}135 - Serie A {'\n'}136 - Serie B {'\n'}140 - La Liga{'\n'}943 - Lega PRO {'\n'}</title>   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                            </div>
                                            <input className='team_text input_text' value={competitionValue} onChange={(e) => handleChange(e, 'competition_value')} type='text' />
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Free Play
                                            </div>
                                            <div className="team_text">
                                                <Switch onChange={(e) => handleChange(e, 'free_play')} checked={freePlay} offColor={'#c20000'} />
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                {visible ? 'Visible' : 'Not Visible'}
                                            </div>
                                            <div className="team_text">
                                                <Switch onChange={(e) => handleChange(e, 'visible')} checked={visible} offColor={'#c20000'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="surv-text-white flex">
                                        <div className="modal_content_title">
                                            <div Style={'position: absolute; bottom: 30px;'}>

                                                <button className='save_button' Style={'margin-right:30px'} onClick={() => createLeague()}>Conferma</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={addTeamPopup}
                            onAfterOpen={null}
                            onRequestClose={() => setAddTeamPopup(false)}
                            style={customModalStylesConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Creazione nuovo Team </div>


                                    <div className="close_button flex" >


                                        <svg onClick={() => setAddTeamPopup(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>


                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Nome Team
                                            </div>
                                            <input className='select_text input_text' type='text' value={team_name} onChange={(e) => handleChange(e, 'team_name')} />
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Primo Colore
                                            </div>
                                            <select className='select_text ' onChange={(e) => handleChange(e, 'first_color')}>

                                                {colorsOptionFirstColor}
                                            </select>
                                        </div>
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Secondo Colore
                                            </div>
                                            <select className='select_text ' onChange={(e) => handleChange(e, 'second_color')}>
                                                {colorsOptionFirstColor}
                                            </select>
                                        </div>
                                        <div className="mt-3">
                                            <div className="flex admin_text" >
                                                <div className="tshirt_template"></div>
                                                <div Style={'margin-left:4px; width: 5px; z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                                <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                                <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                                <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                                <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                                <div Style={'width: 5px;z-index:1;  min-height:57px;  background:' + firstColor}></div>
                                                <div Style={'width: 5px;  min-height:57px;  background:' + secondColor}></div>
                                            </div>
                                        </div>
                                        {teamsView ? '' : <div className="mt-3">
                                            <div className='admin_text'>
                                                Vuoi collegare il team ad una lega?
                                            </div>
                                            <div className="team_text">
                                                <Switch onChange={(e) => handleChange(e, 'league_link')} checked={leagueLink} offColor={'#c20000'} />
                                            </div>
                                            {!leagueLink ? '' :
                                                <div className="mt-3"> <select className='select_text ' onChange={(e) => handleChange(e, 'league_id')}>
                                                    {leagueOptions}
                                                </select></div>}
                                        </div>}
                                    </div>
                                    <div className="surv-text-white flex">
                                        <div className="modal_content_title">
                                            <div Style={'position: absolute; bottom: 30px;'}>

                                                <button className='save_button' Style={'margin-right:30px'} onClick={() => createTeam()}>Conferma</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal >
                        <Modal
                            isOpen={deleteConfirmation}
                            onAfterOpen={null}
                            onRequestClose={() => setDeleteConfirmation(false)}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">
                                    <div className="modal_content_title">Rimuovi Team </div>
                                    <div className="close_button flex" >
                                        <svg onClick={() => setDeleteConfirmation(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Vuoi rimuovere il team {toDelete.name} dalla lega?
                                            </div>
                                            <br />
                                            <div className='admin_text'>
                                                *NB Il sistema controllerà che non ci siano match schedulati per questo team in questa lega.
                                            </div>
                                        </div>

                                        <div className="surv-text-white flex">
                                            <div className="modal_content_title">
                                                <div Style={'position: absolute; bottom: 30px;'}>
                                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => deleteTeam()}>Conferma</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal >
                        <Modal
                            isOpen={stopConfirmation}
                            onAfterOpen={null}
                            onRequestClose={() => setStopConfirmation(false)}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">
                                    <div className="modal_content_title">Stop Lega </div>
                                    <div className="close_button flex" >
                                        <svg onClick={() => setStopConfirmation(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Vuoi terminare la lega {toStop.name} ?
                                            </div>
                                            <br />

                                        </div>

                                        <div className="surv-text-white flex">
                                            <div className="modal_content_title">
                                                <div Style={'position: absolute; bottom: 30px;'}>
                                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => stopLega()}>Stop</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal >
                        <Modal
                            isOpen={playConfirmation}
                            onAfterOpen={null}
                            onRequestClose={() => setPlayConfirmation(false)}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">
                                    <div className="modal_content_title">Avvia Lega </div>
                                    <div className="close_button flex" >
                                        <svg onClick={() => setPlayConfirmation(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Vuoi avviare la lega {toPlay.name} ?
                                            </div>
                                            <br />

                                        </div>

                                        <div className="surv-text-white flex">
                                            <div className="modal_content_title">
                                                <div Style={'position: absolute; bottom: 30px;'}>
                                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => playLega()}>Avvia</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal >
                        <Modal
                            isOpen={disabledAddConfirmation}
                            onAfterOpen={null}
                            onRequestClose={() => setDisabledAddConfirmation(false)}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}

                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">
                                    <div className="modal_content_title">Aggiungi Team</div>
                                    <div className="close_button flex" >
                                        <svg onClick={() => setDisabledAddConfirmation(false)} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="modal_content">
                                    <div className="surv-text-white ">
                                        <div className="mt-3">
                                            <div className='admin_text'>
                                                Vuoi aggiungere il team {toDisabledAdd.name} alla lega?
                                            </div>
                                            <br />

                                        </div>

                                        <div className="surv-text-white flex">
                                            <div className="modal_content_title">
                                                <div Style={'position: absolute; bottom: 30px;'}>
                                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => addDisabledTeamConfirm()}>Aggiungi</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal >

                        <Modal
                            isOpen={addCleanTeamsPopup}
                            onAfterOpen={null}
                            onRequestClose={() => closeConfirmModal()}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}
                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Rimuovere tutte le squadre dalla lega?</div>


                                    <div className="close_button flex" >

                                        <svg onClick={() => closeConfirmModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className='save_button_cont'>
                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => removeAllTeams()}>Conferma</button>
                                    <button className='save_button' onClick={() => closeConfirmModal(false)}>Annulla</button>
                                </div>
                            </div >

                        </Modal >

                        <Modal
                            isOpen={addCleanLeaguePopup}
                            onAfterOpen={null}
                            onRequestClose={() => closeConfirmCleanLeagueModal()}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}
                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Rimuovere i match, squadre e configurazioni della lega?</div>


                                    <div className="close_button flex" >

                                        <svg onClick={() => closeConfirmCleanLeagueModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className='save_button_cont'>
                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => cleanLeague()}>Conferma</button>
                                    <button className='save_button' onClick={() => closeConfirmCleanLeagueModal()}>Annulla</button>
                                </div>
                            </div >

                        </Modal >

                        <Modal
                            isOpen={addDeleteLeaguePopup}
                            onAfterOpen={null}
                            onRequestClose={() => closeConfirmDeleteLeagueModal()}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}
                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Eliminare la lega?</div>


                                    <div className="close_button flex" >

                                        <svg onClick={() => closeConfirmDeleteLeagueModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className='save_button_cont'>
                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => deleteLeague()}>Conferma</button>
                                    <button className='save_button' onClick={() => closeConfirmDeleteLeagueModal()}>Annulla</button>
                                </div>
                            </div >

                        </Modal >

                        <Modal
                            isOpen={addConfirmAddMatchdayPopup}
                            onAfterOpen={null}
                            onRequestClose={() => closeConfirmAddMatchdayModal()}
                            style={customConfirmation}
                            className={'modal_base confirm_delete'}
                            appElement={document.getElementById('root') || undefined}
                        >
                            <div className="modal_content_container">
                                <div className="modal_header flex">

                                    <div className="modal_content_title">Giornata presente, aggiungere match a quelli presenti o sovrascrivere la giornata?</div>

                                    <div className="close_button flex" >

                                        <svg onClick={() => closeConfirmAddMatchdayModal()} xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" class="bi bi-x pointer" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className='save_button_cont'>
                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => sendData(true, false)}>Conferma</button>
                                    <button className='save_button' Style={'margin-right:30px'} onClick={() => sendData(true, true)}>Sovrascrivi</button>
                                    <button className='save_button' onClick={() => closeConfirmAddMatchdayModal()}>Annulla</button>
                                </div>
                            </div >

                        </Modal >

                    </div ></React.Fragment>

                <ToastContainer theme="colored" /> </React.Fragment >
    )

}