import React from 'react';
import Select from 'react-select'
export default function RowMatch(props) {
    const [home_score, setHomeScore] = React.useState(null);
    const [away_score, setAwayScore] = React.useState(null);
    const handleScore = (val, type) => {
        if (type === 'home')
            setHomeScore(parseInt(val))
        else
            setAwayScore(parseInt(val))
    }
    {/*}
        <div className="capitalize flex item_center mr-2 mb-10 ml-10 content-center ">{props.match.homeTeam}
            <input className=' w-15 ml-3 surv-input ml-10' type='number' placeholder="Home " onChange={(e) => handleScore(e.target.value, 'home')} />
            &nbsp;   - &nbsp;
            <input className=' w-15 ml-3 surv-input ' type='number' placeholder="Away " onChange={(e) => handleScore(e.target.value, 'away')} />
            &nbsp;       {props.match.awayTeam}
            <button className={home_score != null && away_score != null ? 'button ml-10' : 'disabled_button ml-10'} disabled={home_score == null || away_score == null ? true : false} onClick={() => props.addResult(props.match, home_score, away_score)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg padb-4" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>
            </button>
        </div>
    */}

    return (
        <tr>
            <td>
                {props.match.homeTeam.name}
            </td>
            <td>
                <input className="result_input" type='number' placeholder="Home" onChange={(e) => handleScore(e.target.value, 'home')} />
            </td>
            <td>
                <input className="result_input" type='number' placeholder="Away" onChange={(e) => handleScore(e.target.value, 'away')} />
            </td>
            <td>
                {props.match.awayTeam.name}
            </td>
            <td>
                <button className={home_score != null && away_score != null ? 'button ml-10' : 'disabled_button ml-10'} disabled={home_score == null || away_score == null ? true : false} onClick={() => props.addResult(props.match, home_score, away_score)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-lg padb-4" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                    </svg>
                </button>
            </td>


        </tr>
    )
}
